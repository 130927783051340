import React, { useState, useEffect } from "react";
import MasterTable from "../../Components/MasterTable";
import { GridActionsCellItem } from "@mui/x-data-grid";
import {
  MoreVert,
  ArrowBack,
  RefreshOutlined,
  Label,
} from "@mui/icons-material";
import CDrawer from "../../Components/Container/CusDrawer";
import { axiosGet, axiosPost, axiosPostForm } from "../../lib/api";
import GridContainer from "../../Components/Container/GridContainer";
import Cookies from "js-cookie";
import {
  Box,
  Menu,
  MenuItem,
  Grid,
  FormControl,
  InputLabel,
  Select,
  IconButton,
  TextField,
  Drawer,
  Typography,
  Collapse,
} from "@mui/material";
import CreateButton from "../../Components/buttons/CreateButton";
import CInput from "../../Components/Container/CInput";
import AutoHideAlert from "../../Components/Container/AutoHideAlert";
import AlertDialog from "../../Components/Container/AlertDialog";
import CusToolTip from "../../Components/buttons/CusToolTip";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import PickerWithDateField from "../../Components/buttons/DashboardDatePicker";

const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      // width: 160,
    },
  },
};

export default function Banner() {
  const ACCESS_TOKEN = Cookies.get("spectransyscore_token");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openViewDrawer, setOpenViewDrawer] = useState(false);
  const [openActive, setOpenActive] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [data, setData] = useState([]);
  const [isAll, setIsAll] = useState(false);
  const [sortField, setSortField] = useState("createdDate");
  const [sortOrder, setSortOrder] = useState("descending");
  const [limit, setLimit] = useState("15");
  const [pageNumber, setPageNumber] = useState(1);
  const [dataCount, setdataCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [openDrawerType, setOpenDrawerType] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [singleData, setSingleData] = useState([]);
  const [postError, setPostError] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filtersList, setfiltersList] = useState(false);
  const [isStatusSelected, setIsStatusSelected] = useState(false);
  const [statusFilter, setStatusFilter] = useState("");
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [rowBtn, setRowBtn] = useState(false);
  const [selectedId, setSelectedId] = useState([]);
  const [multistatusValue, setMultistatusValue] = useState("");
  const [openMulitiStatus, setOpenMultistatus] = useState(false);
  const [openMulitiDelete, setOpenMultiDelete] = useState(false);
  const [keyOne, setKeyOne] = useState("");
  const [keyTwo, setKeyTwo] = useState("");
  const [keyThree, setKeyThree] = useState("");
  const [keyFour, setKeyFour] = useState("");
  const [keyFive, setKeyFive] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");
  const open = Boolean(anchorEl);
  const areCheckboxesEnabled = false;

  // Function to get selected row data
  const getSelectedRowsData = () => {
    return rows.filter((row) => rowSelectionModel.includes(row.id));
  };

  const formatDate = (date) => {
    // Split the input date by '/'
    const [day, month, year] = date.split("/");
    // Create a new date object (note: month is 0-indexed)
    const d = new Date(year, month - 1, day);
    const formattedYear = d.getFullYear();
    const formattedMonth = String(d.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const formattedDay = String(d.getDate()).padStart(2, "0");
    return `${formattedYear}-${formattedMonth}-${formattedDay}`;
  };

  const handleDateChange = (newDate) => {
    // setOutStandingDate(formatDate(newDate));
  };

  useEffect(() => {
    const selectedRowsData = getSelectedRowsData();
    const selectedDatauniqids = selectedRowsData.map((row) => row.fullData);
    const cusID = selectedDatauniqids.map((row) => row.dataUniqId);
    setSelectedId(cusID);
    if (cusID.length > 0) {
      setRowBtn(true);
    } else {
      setRowBtn(false);
    }
    // let cusId=selectedDatauniqids.filter((data)=>data._id)
  }, [rowSelectionModel]);

  const handleRefresh = () => {
    setSearchValue("");
    setIsStatusSelected(false);
    setStatusFilter("");
  };

  function formatTo24Hour(time12hr) {
    const [time, modifier] = time12hr.split(" "); // Split the time and AM/PM part
    let [hours, minutes] = time.split(":"); // Split the hours and minutes

    // Convert to 24-hour format
    if (modifier === "PM" && hours !== "12") {
      hours = (parseInt(hours) + 12).toString(); // Add 12 hours for PM except for 12 PM
    } else if (modifier === "AM" && hours === "12") {
      hours = "00"; // Handle 12 AM case, which is 00 in 24-hour format
    }

    // Pad hours and minutes to ensure two digits
    hours = hours.padStart(2, "0");
    minutes = minutes.padStart(2, "0");

    return `${hours}:${minutes}:00`; // Add seconds as "00"
  }

  // Toggel Drawer
  const toggleDrawer = (newOpen, type) => () => {
    // type 1 : Create
    // type 2 : Edit
    setOpenDrawer(newOpen);
    setOpenDrawerType(type);
    handleCloseMenu();
    if (type === 1) {
      setFromDate(null);
      setToDate(null);
      setFromTime(null);
      setToTime(null);
      setKeyOne("");
      setKeyTwo("");
      setKeyThree("");
      setKeyFour("");
      setKeyFive("");
    } else {
      setFromTime(formatTo24Hour(singleData.fromTime));
      setToTime(formatTo24Hour(singleData.toTime));
      setFromDate(singleData.editfromDate);
      setToDate(singleData.edittoDate);
      setKeyOne(singleData.keyStreamOne);
      setKeyTwo(singleData.keyStreamTwo);
      setKeyThree(singleData.keyStreamThree);
      setKeyFour(singleData.keyStreamFour);
      setKeyFive(singleData.keyStreamFive);
    }
  };

  const handleSaveClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    const fullData = item.fullData;
    setSingleData(fullData);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // Open Status change warning box
  const handleStatusOpen = () => {
    setOpenActive(true);
    handleCloseMenu();
  };

  // Open Status change warning box
  const handleViewOpen = () => {
    setOpenViewDrawer(true);
    handleCloseMenu();
  };

  const handleDeleteOpen = () => {
    setOpenDel(true);
    handleCloseMenu();
  };
  const handleMultiDelete = () => {
    setOpenMultiDelete(true);
  };
  // open multi status waring
  const handleMultiStatus = (event) => {
    setOpenMultistatus(true);
    setMultistatusValue(event.target.value);
  };

  // Handler to log the page number on page change
  const handlePageChange = (model, newPage) => {
    let page = model.page + 1;
    setPageNumber(page);
  };

  const handlePageSizeChange = (newPageSize) => {
    setLimit(newPageSize);
    setPageNumber(0);
  };

  // Filter Functions Start
  const handleStatusType = (value) => {
    setPageNumber(1);
    setStatusFilter(value);
    setIsStatusSelected(true);
    // handlefilterBadgeVisible(true)
  };
  // Filter Functions End

  const fetchData = async () => {
    setIsLoading(true);
    axiosGet
      .get(
        `bannersection/get/adminpanel?userToken=${ACCESS_TOKEN}&page=${pageNumber}&limit=${limit}&isAll=${isAll}&sortField=${sortField}&sortOrder=${sortOrder}&query=${searchValue}&activeStatus=${
          statusFilter == 3 ? "" : statusFilter
        }`
      )
      // &activeStatus=${statusFilter == 3 ?"":statusFilter}
      .then((response) => {
        if (
          response.data.message === "Banner Sections retrieved successfully"
        ) {
          setData(response.data.data);
          setdataCount(response.data.totalItems);
          setPageNumber(pageNumber === 0 ? 1 : pageNumber);
          setPageCount(response.data.total_pages);
          setIsLoading(false);
          // setIsAlertVisible(true);
          // setAlertSeverity("success");
          // setAlertMessage("response.data.error");
        } else {
          setIsLoading(false);
          setPostError(response.data.error);
          setIsAlertVisible(true);
          setAlertSeverity("error");
          setAlertMessage("Something went wrong.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(true);
      });
  };

  useEffect(() => {
    fetchData();
  }, [pageNumber, limit, searchValue, statusFilter]);

  // Funtion for create new data or edit existing data
  const handleSubmit = () => {
    setIsButtonDisabled(true);
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
    setIsLoading(true);
    const jsonData = {
      userToken: ACCESS_TOKEN,
      fromDate: fromDate,
      toDate: toDate,
      fromTime: `${fromTime}:00`,
      toTime: `${toTime}:00`,
      keyStreamOne: keyOne,
      keyStreamTwo: keyTwo,
      keyStreamThree: keyThree,
      keyStreamFour: keyFour,
      keyStreamFive: keyFive,
      dataUniqId: singleData?.dataUniqId,
    };
    try {
      if (openDrawerType == 1) {
        axiosPost
          .post(`bannersection/create`, jsonData)
          .then((response) => {
            // Handle the successful POST response here
            // if (response.data.action === "success") {
            // If response data action is 200, show the alert
            setIsAlertVisible(true);
            setAlertSeverity("success");
            setAlertMessage(response.data.message);
            // Cookies.remove("uuid");
            setOpenDrawer(false);
            setIsLoading(false);
            fetchData();
            // } else {
            //   setIsAlertVisible(true);
            //   setAlertSeverity("error");
            //   setIsLoading(false);
            //   setAlertMessage(response.data.message);
            //   setPostError(response.data.message);
            // }
          })
          .catch((error) => {
            // Handle POST errors here
            console.error("POST Error:", error);
            setIsAlertVisible(true);
            setAlertSeverity("error");
            setIsLoading(false);
            setAlertMessage(error?.response.data.error);
          });
      } else {
        axiosPost
          .put(`bannersection/update`, jsonData)
          .then((response) => {
            // Handle the successful POST response here
            if (
              response.data.message === "BannerSection updated successfully"
            ) {
              // If response data action is 200, show the alert
              setIsAlertVisible(true);
              setAlertSeverity("success");
              setAlertMessage("Banner updated successfully");
              // Cookies.remove("uuid");
              setOpenDrawer(false);
              setIsLoading(false);
              fetchData();
            } else {
              setIsAlertVisible(true);
              setAlertSeverity("error");
              setIsLoading(false);
              setAlertMessage(response.error);
              // setPostError(response.data.message);
            }
          })
          .catch((error) => {
            // Handle POST errors here
            console.error("POST Error:", error);
            setIsAlertVisible(true);
            setAlertSeverity("error");
            setIsLoading(false);
            setAlertMessage(error?.response.data.error);
          });
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  // Funtion for change status of single data
  const handleActiveChange = () => {
    setIsLoading(true);
    const jsonData = {
      userToken: ACCESS_TOKEN,
      // dataUniqIds: [singleData.dataUniqId],
      dataUniqIds: selectedId.length > 0 ? selectedId : [singleData.dataUniqId],
      activeStatus: multistatusValue || singleData.activeStatus === 1 ? 0 : 1,
    };
    axiosPost
      .post(`attendee/change/activestatus`, jsonData)
      .then((response) => {
        if (response.data.message === "Active status updated successfully") {
          // setEffectToggle(!effectToggle);
          // setSelectedItems([]);
          // setActionData("");
          setAlertMessage("Updated successfully.");
          setIsAlertVisible(true);
          setAlertSeverity("success");
          handleCloseMenu();
          setIsLoading(false);
          fetchData();
          setSelectedId("");
          setMultistatusValue("");
          setRowSelectionModel("");
        }
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error:", error);
      });
  };

  // Funtion for delete single data
  const handleDelete = () => {
    setIsLoading(true);
    const jsonData = {
      userToken: ACCESS_TOKEN,
      dataUniqIds: selectedId.length > 0 ? selectedId : [singleData.dataUniqId],
    };
    axiosPost
      .delete(`bannersection/delete`, { data: jsonData })
      .then((response) => {
        // if (response.data.message === "Attendees deleted successfully") {
        // setEffectToggle(!effectToggle);
        handleCloseMenu();
        setAlertMessage("Deleted successfully.");
        setIsAlertVisible(true);
        setAlertSeverity("success");
        setIsLoading(false);
        fetchData();
        // }
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error:", error);
      });
  };

  const columns = [
    // {
    //   field: "id",
    //   headerName: "#",
    //   flex: 1,
    //   width: 60,
    //   maxWidth: 80,
    //   // disableColumnMenu: true,
    //   headerClassName: "super-app-theme--header",
    // },
    {
      field: "fromDate",
      headerName: "From",
      flex: 1,
      minWidth: 100,
      maxWidth: 150,
      // disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
      sortable: false,
    },
    {
      field: "toDate",
      headerName: "To ",
      flex: 1,
      maxWidth: 150,
      minWidth: 100,
      // disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
      sortable: false,
    },

    {
      field: "keyPoints",
      headerName: "Key Points",
      flex: 1,
      minWidth: 100,
      // disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => <CusToolTip text={params.value} arrow />,
    },

    // {
    //   field: "status",
    //   headerName: "Status",
    //   flex: 1,
    //   maxWidth: 200,
    //   minWidth: 100,
    //   // disableColumnMenu: true,
    //   headerClassName: "super-app-theme--header",
    //   renderCell: (params) => {
    //     const status = params.value; // 'app' or 'rej'
    //     const style = {
    //       color:
    //         status === "Inactive"
    //           ? "#FFA500"
    //           : status === "Active"
    //           ? "#008000"
    //           : null,
    //     };

    //     return <span style={style}>{status}</span>;
    //   },
    // },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      // disableColumnMenu: true,
      flex: 1,
      maxWidth: 100,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <GridActionsCellItem
          icon={<MoreVert />}
          label="Save"
          sx={{ position: "relative", color: "primary.main" }}
          onClick={(event) => handleSaveClick(event, params.row)}
        />
      ),
    },
  ];

  const rows = data?.map((item, index) => ({
    id: index + 1,
    fromDate: `${item.fromDate} ${item.fromTime && item.fromTime}`,
    toDate: `${item.toDate} ${item.toTime && item.toTime}`,
    keyPoints: [
      item.keyStreamOne,
      item.keyStreamTwo,
      item.keyStreamThree,
      item.keyStreamFour,
      item.keyStreamFive,
    ]
      .map((keyPoint, i) => `${i + 1}. ${keyPoint}`)
      .join(".  \n"),
    // status:
    //   item.activeStatus === 0
    //     ? "Inactive"
    //     : item.activeStatus === 1
    //     ? "Active"
    //     : null,
    fullData: item,
  }));

  const title = "Banner";
  const boxSx = {
    "& .super-app-theme--header": {
      backgroundColor: "#0088AB",
      color: "white",
      fontSize: "16px",
      fontWeight: "bold",
    },
    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
      {
        backgroundColor: "#0088AB",
      },
    "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
      color: "white",
    },
    "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root.Mui-checked": {
      color: "white",
    },
  };

  const FilterComponent = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 1,
        }}
      >
        <IconButton onClick={handleRefresh} size="small">
          <RefreshOutlined />
        </IconButton>
        <FormControl size="small" sx={{ minWidth: "150px" }}>
          <InputLabel sx={{ fontSize: "14px" }} id="game">
            {"Status"}
          </InputLabel>
          <Select
            MenuProps={MenuProps}
            sx={{
              fontSize: "14px",
              height: "34px",
              bgcolor: isStatusSelected ? "#185aa617" : undefined,
            }}
            placeholder={"Status"}
            labelId="status"
            id="status"
            label={"Status"}
            value={statusFilter}
            onChange={(e) => handleStatusType(e.target.value)}
          >
            <MenuItem sx={{ fontSize: "14px" }} value={3}>
              All
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={1}>
              Active
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={0}>
              Inactive
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
    );
  };

  const disableCreate = dataCount;

  const convertHtmlToText = (text) => {
    const doc = new DOMParser().parseFromString(text, "text/html");
    return doc.body.textContent || "";
  };

  return (
    <>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        flexDirection={{ xs: "column", md: "row" }}
      >
        <CreateButton
          heading={title}
          pagecount={dataCount}
          onAddClick={toggleDrawer(true, 1)}
          disableCreate={disableCreate}
        />
        {/* <Box display={"flex"} alignItems={"center"} gap={1.5}>
          {" "}
          <SearchFilter
            onSearchButtonClick={handleSearchInputChange}
            searchValue={searchValue}
          />
          <Badge
            color="secondary"
            variant="dot"
            invisible={!handlefilterBadgeVisible()}
          >
            <FilterButton
              HandleChangeFilter={HandleChangeFilter}
              filtersList={filtersList}
            />
          </Badge>
        </Box> */}
      </Box>

      <Collapse in={filtersList} timeout="auto" unmountOnExit>
        <Box sx={{ display: "flex", justifyContent: "end", py: 0.5 }}>
          {FilterComponent()}
        </Box>
      </Collapse>
      <Box sx={{ overflow: "auto" }}>
        <MasterTable
          rows={rows}
          columns={columns}
          areCheckboxesEnabled={areCheckboxesEnabled}
          title={title}
          limit={limit}
          loading={isLoading}
          boxSx={boxSx}
          handlePageChange={handlePageChange}
          handlePageSizeChange={handlePageSizeChange}
          rowCount={dataCount}
          paginationModel={{ pageNumber, limit }}
          handleMultiDelete={handleMultiDelete}
          handleMultiStatus={handleMultiStatus}
          multistatusValue={multistatusValue}
          setRowSelectionModel={setRowSelectionModel}
          rowBtn={rowBtn}
          rowSelectionModel={rowSelectionModel}
        />
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={toggleDrawer(true, 2)} sx={{ width: 140 }}>
          Edit
        </MenuItem>
        <MenuItem onClick={handleViewOpen}>View</MenuItem>
        {/* <MenuItem onClick={handleStatusOpen}>
          Mark as {singleData.activeStatus === 0 ? "active" : "inactive"}
        </MenuItem> */}
        <MenuItem onClick={handleDeleteOpen}>Delete</MenuItem>
      </Menu>

      {/* main drawer  */}
      <CDrawer
        open={openDrawer}
        close={() => setOpenDrawer(false)}
        title={title}
        loading={isButtonDisabled}
        buttonName={openDrawerType === 1 ? "Create" : "Save"}
        openType={openDrawerType}
        onSave={handleSubmit}
      >
        {/* Content for CDrawer */}
        <GridContainer spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label="From Date"
              type="date"
              variant="outlined"
              value={fromDate}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setFromDate(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="To Date"
              type="date"
              variant="outlined"
              value={toDate}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: { min: fromDate },
              }}
              disabled={!fromDate}
              onChange={(e) => setToDate(e.target.value)}
              fullWidth
            />
          </Grid>

          {/* From Time */}
          <Grid item xs={12} md={6}>
            <TextField
              label="From Time"
              type="time"
              variant="outlined"
              value={fromTime}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setFromTime(e.target.value)}
              fullWidth
            />
          </Grid>

          {/* To Time */}
          <Grid item xs={12} md={6}>
            <TextField
              label="To Time"
              type="time"
              variant="outlined"
              value={toTime}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: { min: fromTime },
              }}
              disabled={!fromTime}
              onChange={(e) => setToTime(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={"14px"}>Key One</Typography>
            {/* <CInput
              label="Key One"
              value={keyOne}
              name="Key"
              onChange={(e) => setKeyOne(e.target.value)}
            /> */}
            <ReactQuill theme="snow" value={keyOne} onChange={setKeyOne} />
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={"14px"}>Key Two</Typography>
            {/* <CInput
              label="Key Two"
              value={keyTwo}
              name="Key"
              onChange={(e) => setKeyTwo(e.target.value)}
            /> */}
            <ReactQuill theme="snow" value={keyTwo} onChange={setKeyTwo} />
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={"14px"}>Key Three</Typography>
            {/* <CInput
              label="Key Three"
              value={keyThree}
              name="Key"
              onChange={(e) => setKeyThree(e.target.value)}
            /> */}
            <ReactQuill theme="snow" value={keyThree} onChange={setKeyThree} />
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={"14px"}>Key Four</Typography>
            {/* <CInput
              label="Key Four"
              value={keyFour}
              name="Key"
              onChange={(e) => setKeyFour(e.target.value)}
            /> */}
            <ReactQuill theme="snow" value={keyFour} onChange={setKeyFour} />
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={"14px"}>Key Five</Typography>
            {/* <CInput
              label="Key Five"
              value={keyFive}
              name="Key"
              onChange={(e) => setKeyFive(e.target.value)}
            /> */}
            <ReactQuill theme="snow" value={keyFive} onChange={setKeyFive} />
          </Grid>
        </GridContainer>
      </CDrawer>
      {/* main drawer end  */}

      {/* view drawer  */}
      <Drawer
        anchor={"right"}
        open={openViewDrawer}
        onClose={() => setOpenViewDrawer(false)}
        title={title}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            py: 1,
            borderBottom: "1px solid #3636363b",
          }}
        >
          <IconButton onClick={() => setOpenViewDrawer(false)} size="small">
            <ArrowBack style={{ color: "black" }} />
          </IconButton>
          <Typography
            variant="h6"
            className="nunito_font"
            style={{
              fontSize: "18px",
              fontWeight: 700,
              color: "#185AA6",
            }}
          >
            View {title}
          </Typography>
        </Box>
        <Box sx={{ width: 400, p: 2 }}>
          <GridContainer>
            <Grid item xs={12} display={"flex"} flexDirection={"column"}>
              <Typography sx={{ color: "gray" }}>From</Typography>
              <Typography
                variant="h5"
                component="h2"
                sx={{ px: 1.5, fontSize: "12px" }}
                textTransform={"capitalize"}
              >
                {singleData.fromDate}
                {", "}
                {singleData.fromTime}
              </Typography>
            </Grid>
            <Grid item xs={12} display={"flex"} flexDirection={"column"}>
              <Typography sx={{ color: "gray" }}>To</Typography>
              <Typography
                variant="h5"
                component="h2"
                sx={{ px: 1.5, fontSize: "12px" }}
                textTransform={"capitalize"}
              >
                {singleData.toDate}
                {", "}
                {singleData.toTime}
              </Typography>
            </Grid>
            <Grid item xs={12} display={"flex"} flexDirection={"column"}>
              <Typography sx={{ color: "gray" }}>Key One</Typography>
              <Typography
                variant="h5"
                component="h2"
                sx={{ px: 1.5, fontSize: "12px" }}
              >
                {convertHtmlToText(singleData.keyStreamOne)}
              </Typography>
            </Grid>

            <Grid item xs={12} display={"flex"} flexDirection={"column"}>
              <Typography sx={{ color: "gray" }}>Key Two</Typography>
              <Typography
                variant="h5"
                component="h2"
                sx={{ px: 1.5, fontSize: "12px" }}
              >
                {convertHtmlToText(singleData.keyStreamTwo)}
              </Typography>
            </Grid>

            <Grid item xs={12} display={"flex"} flexDirection={"column"}>
              <Typography sx={{ color: "gray" }}>Key Three</Typography>
              <Typography
                variant="h5"
                component="h2"
                sx={{ px: 1.5, fontSize: "12px" }}
              >
                {convertHtmlToText(singleData.keyStreamThree)}
              </Typography>
            </Grid>

            <Grid item xs={12} display={"flex"} flexDirection={"column"}>
              <Typography sx={{ color: "gray" }}>Key Four</Typography>
              <Typography
                variant="h5"
                component="h2"
                sx={{ px: 1.5, fontSize: "12px" }}
              >
                {" "}
                {convertHtmlToText(singleData.keyStreamFour)}
              </Typography>
            </Grid>

            <Grid item xs={12} display={"flex"} flexDirection={"column"}>
              <Typography sx={{ color: "gray" }}>Key Five</Typography>
              <Typography
                variant="h5"
                component="h2"
                sx={{ px: 1.5, fontSize: "12px" }}
                textTransform={"capitalize"}
              >
                {" "}
                {convertHtmlToText(singleData.keyStreamFive)}
              </Typography>
            </Grid>
          </GridContainer>
        </Box>
      </Drawer>
      {/* view drawer end  */}

      <AlertDialog
        onsubmit={handleActiveChange}
        open={openActive}
        handleClose={() => setOpenActive(false)}
        text={`Are you sure want to ${
          singleData.activeStatus == 1 ? "Active" : "Inactive"
        } ?`}
      ></AlertDialog>
      <AlertDialog
        onsubmit={handleActiveChange}
        open={openMulitiStatus}
        handleClose={() => setOpenMultistatus(false)}
        text={`Are you sure want to change status of ${selectedId.length} items?`}
      ></AlertDialog>
      <AlertDialog
        onsubmit={handleDelete}
        open={openDel}
        handleClose={() => setOpenDel(false)}
        text={"Are you sure want to delete ?"}
      ></AlertDialog>
      <AlertDialog
        onsubmit={handleDelete}
        open={openMulitiDelete}
        handleClose={() => setOpenMultiDelete(false)}
        text={`Are you sure want to delete ${selectedId.length} items?`}
      ></AlertDialog>
      {isAlertVisible && (
        <AutoHideAlert
          severity={alertSeverity}
          message={alertMessage}
          autoHideDuration={3000}
          open={isAlertVisible}
          onClose={() => setIsAlertVisible(false)}
        />
      )}
    </>
  );
}
