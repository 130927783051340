import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Collapse,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Delete } from "@mui/icons-material";

export default function MasterTable({
  rows,
  columns,
  loading,
  boxSx,
  handlePageChange,
  rowCount,
  paginationModel,
  limit,
  handleMultiDelete,
  handleMultiStatus,
  handleAppMultiStatus,
  setRowSelectionModel,
  rowBtn,
  rowSelectionModel,
  multistatusValue,
  multiAppStsValue,
  areCheckboxesEnabled,
  multiAppStatus,
  handleSortModelChange,
}) {
  const getRowHeight = (params) => params.rowHeight || 44;

  return (
    <Box
      sx={{
        ...boxSx,
        padding: "12px",
        margin: "0 0 10px 0 !important",
        borderRadius: "8px",
        border: "1px solid #e0e0e0",
        background: "#fff",
      }}
    >
      <Collapse in={rowBtn}>
        <Box
          display={"flex"}
          width={"100%"}
          justifyContent={"end"}
          sx={{ bgcolor: "white", p: 1.5, pb: 1, pt: 0 }}
        >
          <IconButton
            variant="contained"
            color="primary"
            onClick={handleMultiDelete}
          >
            <Delete sx={{ color: "#FF0000" }} />
          </IconButton>
          {multiAppStatus ? (
            <FormControl size="small" sx={{ minWidth: "150px", mr: "10px" }}>
              <InputLabel sx={{ fontSize: "14px" }} id="game">
                {"Approval Status"}
              </InputLabel>
              <Select
                sx={{
                  fontSize: "14px",
                  // height: "34px",
                  // bgcolor: isStatusSelected ? "#185aa617" : undefined,
                }}
                placeholder={"Approval Status"}
                labelId="Approval status"
                id="status"
                label={"Approval Status"}
                value={multiAppStsValue}
                onChange={handleAppMultiStatus}
              >
                <MenuItem sx={{ fontSize: "14px" }} value={0}>
                  Approve
                </MenuItem>
                <MenuItem sx={{ fontSize: "14px" }} value={1}>
                  Cancel
                </MenuItem>
              </Select>
            </FormControl>
          ) : null}
          <FormControl size="small" sx={{ minWidth: "150px" }}>
            <InputLabel sx={{ fontSize: "14px" }} id="game">
              {"Active status"}
            </InputLabel>
            <Select
              sx={{
                fontSize: "14px",
                // height: "34px",
                // bgcolor: isStatusSelected ? "#185aa617" : undefined,
              }}
              placeholder={"Status"}
              labelId="status"
              id="status"
              label={"Active status"}
              value={multistatusValue}
              onChange={handleMultiStatus}
            >
              <MenuItem sx={{ fontSize: "14px" }} value={0}>
                Active
              </MenuItem>
              <MenuItem sx={{ fontSize: "14px" }} value={1}>
                Inactive
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Collapse>
      <DataGrid
        sx={{
          background: "#fafafa",
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-columnHeader:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-columnHeader:focus-within": {
            outline: "none",
          },
        }}
        rows={rows}
        columns={columns}
        rowCount={rowCount}
        pagination
        pageSize={paginationModel.pageSize}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: limit },
          },
        }}
        rowsPerPage={limit}
        pageSizeOptions={[5, 15, 25,50]}
        paginationMode="server"
        onPaginationModelChange={handlePageChange}
        loading={loading}
        autoHeight
        checkboxSelection={areCheckboxesEnabled}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
        disableRowSelectionOnClick
        disableColumnMenu
        getRowHeight={getRowHeight}
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
      />
    </Box>
  );
}
