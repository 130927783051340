import React from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Drawer,
  CircularProgress,
} from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function CDrawer({
  position = "right",
  open,
  close,
  openType,
  title,
  buttonName,
  children,
  onSave,
  loading,
  width,
}) {
  return (
    <Drawer anchor={position} open={open} onClose={close} sx={{ zIndex: 2 }}>
      <Box sx={{ minWidth: 400, pb: 1, maxWidth: 650, width:width }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pt: 1,
            px: 1,
            zIndex: "1000000",
            top: "0px",
            backgroundColor: "white",
            position: "sticky",
            borderBottom: "1px solid #3636363b",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center",gap:0.5 }}>
            <IconButton onClick={close} size="small">
              <ArrowBackIcon style={{ color: "black", width:18 }} />
            </IconButton>
            <Typography
              variant="h6"
              className="nunito_font"
              style={{
                fontSize: "18px",
                fontWeight: 700,
                color: "#185AA6",
              }}
            >
              {openType === 1 ? "Create" : openType === 2 ? "Edit" : null}{" "}
              {title}
            </Typography>
          </Box>
          <Box>
            <Button
              onClick={onSave}
              size="small"
              variant="contained"
              disabled={loading}
            >
              {/* {loading ? (
                <CircularProgress size={22} />
              ) : ( */}
                <>{buttonName}</>
              {/* )} */}
            </Button>
          </Box>
        </Box>
        <Box sx={{ p: 1, px: 2, my: 1 }}>{children}</Box>
      </Box>
    </Drawer>
  );
}
