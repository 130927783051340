import React, { useState, useEffect, useRef } from "react";
import MasterTable from "../../Components/MasterTable";
import { GridActionsCellItem } from "@mui/x-data-grid";
import {
  Add,
  CloseOutlined,
  MoreVert,
  Close,
  ArrowBack,
  RefreshOutlined,
  FileUpload,
  CloseSharp,
} from "@mui/icons-material";
import CDrawer from "../../Components/Container/CusDrawer";
import { axiosGet, axiosPost, axiosPostForm } from "../../lib/api";
import GridContainer from "../../Components/Container/GridContainer";
import Cookies from "js-cookie";
import {
  Box,
  Menu,
  MenuItem,
  Grid,
  Stack,
  FormControl,
  InputLabel,
  Select,
  Chip,
  IconButton,
  TextField,
  capitalize,
  Drawer,
  Typography,
  Collapse,
  Badge,
  styled,
  Button,
} from "@mui/material";
import CreateButton from "../../Components/buttons/CreateButton";
import CInput from "../../Components/Container/CInput";
import AutoHideAlert from "../../Components/Container/AutoHideAlert";
import AlertDialog from "../../Components/Container/AlertDialog";
import CAlertDialog from "../../Components/Container/CAlertDialog";
import SearchFilter from "../../Components/buttons/SearchFilter";
import FilterButton from "../../Components/buttons/FilterButton";
import { API_ENDPOINT_IMG, capitalizeText } from "../../lib/config";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CusToolTip from "../../Components/buttons/CusToolTip";
import { sanitizeDescription } from "../../lib/utils";

const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      // width: 160,
    },
  },
};

export default function Event() {
  const ACCESS_TOKEN = Cookies.get("spectransyscore_token");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openViewDrawer, setOpenViewDrawer] = useState(false);
  const [openFeeDrawer, setOpenFeeDrawer] = useState(false);
  const [openActive, setOpenActive] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [data, setData] = useState([]);
  const [isAll, setIsAll] = useState(false);
  const [sortField, setSortField] = useState("createdDate");
  const [sortOrder, setSortOrder] = useState("descending");
  const [limit, setLimit] = useState("15");
  const [pageNumber, setPageNumber] = useState(1);
  const [dataCount, setdataCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [openDrawerType, setOpenDrawerType] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [singleData, setSingleData] = useState([]);
  const [postError, setPostError] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filtersList, setfiltersList] = useState(false);
  const [isStatusSelected, setIsStatusSelected] = useState(false);
  const [statusFilter, setStatusFilter] = useState("");
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [rowBtn, setRowBtn] = useState(false);
  const [selectedId, setSelectedId] = useState([]);
  const [multistatusValue, setMultistatusValue] = useState("");
  const [openMulitiStatus, setOpenMultistatus] = useState(false);
  const [openMulitiDelete, setOpenMultiDelete] = useState(false);
  const [eventTitle, setEventTitle] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [when, setWhen] = useState("");
  const [where, setWhere] = useState("");
  const [media, setMedia] = useState(null);
  const open = Boolean(anchorEl);
  const areCheckboxesEnabled = false;

  const [mediaName, setMediaName] = useState("");
  const [eventFee, setEventFee] = useState("");
  const [mediaUrl, setMediaUrl] = useState(null);
  const [mediaExUrl, setMediaExUrl] = useState(null);



  const sanitizedDescription = sanitizeDescription(singleData?.description);

  const [base64Images, setBase64Images] = React.useState([]); // New images
  const [imagesPreview, setImagesPreview] = React.useState([]); // For previewing new + existing images
  const [deleteImages, setDeleteImages] = React.useState([]); // For storing images to delete
  const [existingImages, setExistingImages] = React.useState([]); // Fetched from server

  const maxSizeInMB = 2;
  const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
  const [sizeError, setSizeError] = useState("");

  // Handle multiple image uploads
  const onImageUpload = (event) => {
    const selectedFiles = Array.from(event.target.files);
    if (selectedFiles.length === 0) return;
    if (selectedFiles.size > maxSizeInBytes) {
      setSizeError(
        `File size exceeds ${maxSizeInMB} MB. Please select a smaller file.`
      );
      setIsButtonDisabled(true);
      return;
    }

    const validImageFiles = selectedFiles.filter(
      (file) => file.type.startsWith("image/") && file.size <= maxSizeInBytes
    );

    if (validImageFiles.length !== selectedFiles.length) {
      alert("Only image files under 2MB are allowed. Some files were ignored.");
    }

    const images = [];

    const processFile = (index) => {
      if (index >= validImageFiles.length) {
        setBase64Images((prevBase64Images) => [...prevBase64Images, ...images]);
        setImagesPreview((prev) => [...prev, ...images]);
        return;
      }

      const file = validImageFiles[index];
      const reader = new FileReader();
      // setPicture(file);
      // setPictureUrl(URL.createObjectURL(file));
      // setPictureName(file.name);
      reader.onload = (e) => {
        const base64String = file;
        const imageName = file.name;
        const imageUrl = URL.createObjectURL(file);

        images.push({
          image_name: imageName,
          image_data: base64String,
          image_path: imageUrl,
        });

        processFile(index + 1);
      };

      reader.readAsDataURL(file);
    };

    processFile(0);
  };

  // Reset image handler
  const resetImage = (index) => {
    // Check if the image is an existing one or a newly uploaded one
    if (index < existingImages.length) {
      // It's an existing image
      const imageToDelete = existingImages[index];
      setDeleteImages((prevDeleteImages) => [
        ...prevDeleteImages,
        imageToDelete,
      ]);

      // Remove the image from existing images
      setExistingImages((prevExistingImages) =>
        prevExistingImages.filter((_, i) => i !== index)
      );
    } else {
      // It's a newly uploaded image
      const adjustedIndex = index - existingImages.length;
      setBase64Images((prevBase64Images) =>
        prevBase64Images.filter((_, i) => i !== adjustedIndex)
      );
    }

    // Remove the image from preview
    setImagesPreview((prev) => prev.filter((_, i) => i !== index));
  };

  const [error, setError] = useState("");
  const [textLength, setTextLength] = useState("0");

  const handleQuillChange = (value) => {
    const plainText = value.replace(/<[^>]+>/g, "");
    setTextLength(plainText.length);
    if (plainText.length <= 3000) {
      setDescription(value);
      setIsButtonDisabled(false);

      setError(""); // Clear error if within limit
    } else {
      setError("Exceeded maximum character limit of 3000.");
      setIsButtonDisabled(true);
    }
  };

  // Function to get selected row data
  const getSelectedRowsData = () => {
    return rows.filter((row) => rowSelectionModel.includes(row.id));
  };

  useEffect(() => {
    const selectedRowsData = getSelectedRowsData();
    const selectedDatauniqids = selectedRowsData.map((row) => row.fullData);
    const cusID = selectedDatauniqids.map((row) => row.dataUniqId);
    setSelectedId(cusID);
    if (cusID.length > 0) {
      setRowBtn(true);
    } else {
      setRowBtn(false);
    }
    // let cusId=selectedDatauniqids.filter((data)=>data._id)
  }, [rowSelectionModel]);

  const handleRefresh = () => {
    setSearchValue("");
    setIsStatusSelected(false);
    setStatusFilter("");
  };

  // Toggel Drawer
  const toggleDrawer = (newOpen, type) => () => {
    // type 1 : Create
    // type 2 : Edit
    setOpenDrawer(newOpen);
    setOpenDrawerType(type);
    handleCloseMenu();
    if (type === 1) {
      setEventTitle("");
      setMedia("");
      setMediaUrl("");
      setMediaName("");
      setDescription("");
      setImagesPreview([]);
    } else {
      setEventTitle(singleData?.title);
      setMedia(singleData?.media);
      setMediaUrl(singleData?.media);
      setMediaName("");
      setImagesPreview(singleData?.media);
      setExistingImages(singleData?.existingMediaPath);
      setDescription(sanitizedDescription);
      setLocation(singleData?.location);
      setWhen(singleData?.when);
      setWhere(singleData?.where);
    }
  };

  const handleSaveClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    const fullData = item.fullData;
    setSingleData(fullData);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // Open Status change warning box
  const handleStatusOpen = () => {
    setOpenActive(true);
    handleCloseMenu();
  };

  // Open Status change warning box
  const handleViewOpen = () => {
    setOpenViewDrawer(true);
    handleCloseMenu();
  };

  // Open Status change warning box
  const handleFee = () => {
    setOpenFeeDrawer(true);
    handleCloseMenu();
    setEventFee(singleData?.eventFee);
  };

  const handleDeleteOpen = () => {
    setOpenDel(true);
    handleCloseMenu();
  };
  const handleMultiDelete = () => {
    setOpenMultiDelete(true);
  };
  // open multi status waring
  const handleMultiStatus = (event) => {
    setOpenMultistatus(true);
    setMultistatusValue(event.target.value);
  };

  // Handler to log the page number on page change
  const handlePageChange = (model, newPage) => {
    let page = model.page + 1;
    setPageNumber(page);
  };

  const handlePageSizeChange = (newPageSize) => {
    setLimit(newPageSize);
    setPageNumber(0);
  };

  // Filter Functions Start
  const handleStatusType = (value) => {
    setPageNumber(1);
    setStatusFilter(value);
    setIsStatusSelected(true);
    // handlefilterBadgeVisible(true)
  };
  // Filter Functions End

  const fetchData = async () => {
    setIsLoading(true);
    axiosGet
      .get(
        `eventsection/get/admin?userToken=${ACCESS_TOKEN}&page=${pageNumber}&limit=${limit}&isAll=${isAll}&sortField=${sortField}&sortOrder=${sortOrder}&query=${searchValue}&activeStatus=${
          statusFilter == 3 ? "" : statusFilter
        }`
      )
      // &activeStatus=${statusFilter == 3 ?"":statusFilter}
      .then((response) => {
        if (response.data.message === "Event Sections retrieved successfully") {
          setData(response.data.data);
          // setImages(response.data.data[0].media)
          setdataCount(response.data.totalItems);
          setPageNumber(pageNumber === 0 ? 1 : pageNumber);
          setPageCount(response.data.total_pages);
          setIsLoading(false);
          // setIsAlertVisible(true);
          // setAlertSeverity("success");
          // setAlertMessage("response.data.error");
        } else {
          setIsLoading(false);
          setPostError(response.data.error);
          setIsAlertVisible(true);
          setAlertSeverity("error");
          setAlertMessage("Something went wrong.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(true);
      });
  };

  useEffect(() => {
    fetchData();
  }, [pageNumber, limit, searchValue, statusFilter]);

  // Funtion for create new data or edit existing data
  const handleFeeSubmit = () => {
    setIsButtonDisabled(true);
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
    setIsLoading(true);
    const jsonData = {
      dataUniqId: singleData?.dataUniqId,
      userToken: ACCESS_TOKEN,
      eventFee: eventFee,
    };
    try {
      axiosPostForm
        .post(`eventsection/set/fee`, jsonData)
        .then((response) => {
          // Handle the successful POST response here
          if (response.data.message === "Event fee updated successfully") {
            // If response data action is 200, show the alert
            setIsAlertVisible(true);
            setAlertSeverity("success");
            setAlertMessage(response.data.message);
            // Cookies.remove("uuid");
            setOpenFeeDrawer(false);
            setIsLoading(false);
            fetchData();
          } else {
            setIsAlertVisible(true);
            setAlertSeverity("error");
            setIsLoading(false);
            setAlertMessage(response.data.message);
            // setPostError(response.data.message);
          }
        })
        .catch((error) => {
          // Handle POST errors here
          console.error("POST Error:", error);
          setIsAlertVisible(true);
          setAlertSeverity("error");
          setIsLoading(false);
          setAlertMessage(error?.response.data.error);
        });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleSubmit = () => {
    setIsButtonDisabled(true);
    setIsLoading(true);

    // Timeout to re-enable the button after 3 seconds
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);

    const formData = new FormData();

    // Append simple key-value pairs
    formData.append("userToken", ACCESS_TOKEN);
    formData.append("description", sanitizeDescription(description));
    formData.append("dataUniqId", singleData?.dataUniqId);
    formData.append("location", location);
    formData.append("where", where);
    formData.append("when", when);

    // Handle media based on openDrawerType
    if (openDrawerType === 1) {
      imagesPreview.forEach((image) => {
        formData.append("media", image.image_data);
      });
    } else if (openDrawerType === 2) {
      imagesPreview.forEach((item) => {
        if (typeof item !== "string") {
          formData.append("media", item.image_data);
        } else {
          // const mediaPaths = singleData?.existingMediaPath;
          // mediaPaths.forEach((path) => {
          formData.append("existingMediaPath", item);
          // formData.append("existingMediaPath", path.trim());
          // });
        }
      });
    }

    // Make the API call
    const apiCall =
      openDrawerType === 1
        ? axiosPostForm.post(`eventsection/create`, formData)
        : openDrawerType === 1
        ? axiosPostForm.put(`eventsection/update`, formData)
        : axiosPostForm.put(`eventsection/update`, formData);
    apiCall
      .then((response) => {
        const successMessage =
          openDrawerType === 1
            ? "Event Section created successfully"
            : "Event Section updated successfully";

        if (response.data.message === successMessage) {
          setIsAlertVisible(true);
          setAlertSeverity("success");
          setAlertMessage(response.data.message);
          setOpenDrawer(false);
          fetchData();
        } else {
          setIsAlertVisible(true);
          setAlertSeverity("error");
          setAlertMessage(response.data.message);
        }
      })
      .catch((error) => {
        console.error("POST Error:", error);
        setIsAlertVisible(true);
        setAlertSeverity("error");
        setAlertMessage(error?.response?.data?.error || "An error occurred");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Funtion for change status of single data
  const handleActiveChange = () => {
    setIsLoading(true);
    const jsonData = {
      userToken: ACCESS_TOKEN,
      // dataUniqIds: [singleData?.dataUniqId],
      dataUniqIds:
        selectedId.length > 0 ? selectedId : [singleData?.dataUniqId],
      activeStatus: multistatusValue || singleData?.activeStatus === 1 ? 0 : 1,
    };
    axiosPost
      .post(`eventsection/change/activestatus`, jsonData)
      .then((response) => {
        if (response.data.message === "Active status updated successfully") {
          // setEffectToggle(!effectToggle);
          // setSelectedItems([]);
          // setActionData("");
          setAlertMessage("Updated successfully.");
          setIsAlertVisible(true);
          setAlertSeverity("success");
          handleCloseMenu();
          setIsLoading(false);
          fetchData();
          setSelectedId("");
          setMultistatusValue("");
          setRowSelectionModel("");
        }
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error:", error);
      });
  };

  // Funtion for delete single data
  const handleDelete = () => {
    setIsLoading(true);
    const jsonData = {
      userToken: ACCESS_TOKEN,
      dataUniqIds:
        selectedId.length > 0 ? selectedId : [singleData?.dataUniqId],
    };
    axiosPost
      .delete(`eventsection/delete`, { data: jsonData })
      .then((response) => {
        // if (response.data.message === "Attendees deleted successfully") {
        // setEffectToggle(!effectToggle);
        handleCloseMenu();
        setAlertMessage("Deleted successfully.");
        setIsAlertVisible(true);
        setAlertSeverity("success");
        setIsLoading(false);
        fetchData();
        // }
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error:", error);
      });
  };

  const columns = [
    // {
    //   field: "id",
    //   headerName: "#",
    //   flex: 1,
    //   width: 60,
    //   maxWidth: 80,
    //   // disableColumnMenu: true,
    //   headerClassName: "super-app-theme--header",
    // },
    // {
    //   field: "title",
    //   headerName: "Title",
    //   flex: 1,
    //   minWidth: 100,
    //   maxWidth: 200,
    //   // disableColumnMenu: true,
    //   headerClassName: "super-app-theme--header",
    // },
    {
      field: "when",
      headerName: "When",
      flex: 1,
      minWidth: 100,
      // disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
      sortable: false,
    },
    {
      field: "where",
      headerName: "Where",
      flex: 1,
      minWidth: 100,
      // disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
      sortable: false,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      minWidth: 100,
      // disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => <CusToolTip text={params.value} arrow />,
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
      minWidth: 80,
      maxWidth: 120,
      // disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
      sortable: false,
    },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   flex: 1,
    //   maxWidth: 200,
    //   minWidth: 100,
    //   // disableColumnMenu: true,
    //   headerClassName: "super-app-theme--header",
    //   renderCell: (params) => {
    //     const status = params.value; // 'app' or 'rej'
    //     const style = {
    //       color:
    //         status === "Inactive"
    //           ? "#FFA500"
    //           : status === "Active"
    //           ? "#008000"
    //           : null,
    //     };

    //     return <span style={style}>{status}</span>;
    //   },
    // },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      // disableColumnMenu: true,
      flex: 1,
      maxWidth: 100,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => (
        <GridActionsCellItem
          icon={<MoreVert />}
          label="Save"
          sx={{ position: "relative", color: "primary.main" }}
          onClick={(event) => handleSaveClick(event, params.row)}
        />
      ),
    },
  ];

  const rows = data?.map((item, index) => ({
    id: index + 1,
    // title: item.title,
    when: item.when ? capitalizeText(item.when) : "---",
    where: item.where ? capitalizeText(item.where) : "---",
    description: item.description ? sanitizeDescription(item.description) : "---",
    price: item.eventFee ? item.eventFee : "---",
    // status:
    //   item.activeStatus === 0
    //     ? "Inactive"
    //     : item.activeStatus === 1
    //     ? "Active"
    //     : null,
    fullData: item,
  }));

  const title = "Event";
  const boxSx = {
    "& .super-app-theme--header": {
      backgroundColor: "#0088AB",
      color: "white",
      fontSize: "16px",
      fontWeight: "bold",
    },
    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
      {
        backgroundColor: "#0088AB",
      },
    "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
      color: "white",
    },
    "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root.Mui-checked": {
      color: "white",
    },
  };

  const renderMedia = () => {
    if (!singleData?.media) {
      return "No media content...";
    }

    const mediaUrl = singleData?.media;
    // const isVideo = mediaUrl?.match(/\.(mp4|webm|ogg)$/i);
    // const isImage = mediaUrl?.match(/\.(jpeg|jpg|gif|png)$/i);

    // if (isVideo) {
    //   return (
    //     <video
    //       src={mediaUrl}
    //       style={{ height: 120, borderRadius: "20px" }}
    //       autoPlay
    //       loop
    //       muted
    //       playsInline
    //     />
    //   );
    // }

    // if (isImage) {
    return (
      <img
        src={mediaUrl}
        alt="Media content"
        style={{ height: 120, borderRadius: "20px" }}
      />
    );
    // }

    return "Unsupported media format";
  };

  const FilterComponent = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 1,
        }}
      >
        <IconButton onClick={handleRefresh} size="small">
          <RefreshOutlined />
        </IconButton>
        <FormControl size="small" sx={{ minWidth: "150px" }}>
          <InputLabel sx={{ fontSize: "14px" }} id="game">
            {"Status"}
          </InputLabel>
          <Select
            MenuProps={MenuProps}
            sx={{
              fontSize: "14px",
              height: "34px",
              bgcolor: isStatusSelected ? "#185aa617" : undefined,
            }}
            placeholder={"Status"}
            labelId="status"
            id="status"
            label={"Status"}
            value={statusFilter}
            onChange={(e) => handleStatusType(e.target.value)}
          >
            <MenuItem sx={{ fontSize: "14px" }} value={3}>
              All
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={1}>
              Active
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={0}>
              Inactive
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
    );
  };

  const disableCreate = dataCount;
  // const imagePresence = images || imagesPreview;

  return (
    <>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        flexDirection={{ xs: "column", md: "row" }}
      >
        <CreateButton
          heading={title}
          pagecount={dataCount}
          onAddClick={toggleDrawer(true, 1)}
          disableCreate={disableCreate}
        />
        {/* <Box display={"flex"} alignItems={"center"} gap={1.5}>
          {" "}
          <SearchFilter
            onSearchButtonClick={handleSearchInputChange}
            searchValue={searchValue}
          />
          <Badge
            color="secondary"
            variant="dot"
            invisible={!handlefilterBadgeVisible()}
          >
            <FilterButton
              HandleChangeFilter={HandleChangeFilter}
              filtersList={filtersList}
            />
          </Badge>
        </Box> */}
      </Box>

      <Collapse in={filtersList} timeout="auto" unmountOnExit>
        <Box sx={{ display: "flex", justifyContent: "end", py: 0.5 }}>
          {FilterComponent()}
        </Box>
      </Collapse>
      <Box sx={{ overflow: "auto" }}>
        <MasterTable
          rows={rows}
          columns={columns}
          areCheckboxesEnabled={areCheckboxesEnabled}
          title={title}
          limit={limit}
          loading={isLoading}
          boxSx={boxSx}
          handlePageChange={handlePageChange}
          handlePageSizeChange={handlePageSizeChange}
          rowCount={dataCount}
          paginationModel={{ pageNumber, limit }}
          handleMultiDelete={handleMultiDelete}
          handleMultiStatus={handleMultiStatus}
          multistatusValue={multistatusValue}
          setRowSelectionModel={setRowSelectionModel}
          rowBtn={rowBtn}
          rowSelectionModel={rowSelectionModel}
        />
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={toggleDrawer(true, 2)} sx={{ width: 140 }}>
          Edit
        </MenuItem>
        <MenuItem onClick={handleViewOpen}>View</MenuItem>
        <MenuItem onClick={handleFee}>Event Fee</MenuItem>
        {/* <MenuItem onClick={handleStatusOpen}>
          Mark as {singleData?.activeStatus === 0 ? "active" : "inactive"}
        </MenuItem> */}
        <MenuItem onClick={handleDeleteOpen}>Delete</MenuItem>
      </Menu>

      {/* main drawer  */}
      <CDrawer
        open={openDrawer}
        close={() => setOpenDrawer(false)}
        title={title}
        loading={isButtonDisabled}
        buttonName={openDrawerType === 1 ? "Create" : "Save"}
        openType={openDrawerType}
        onSave={handleSubmit}
      >
        {/* Content for CDrawer */}
        <GridContainer spacing={2}>
          <Grid item xs={6}>
            <CInput
              label="When"
              value={when}
              name="Key"
              onChange={(e) => setWhen(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <CInput
              label="Where"
              value={where}
              name="Key"
              onChange={(e) => setWhere(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <CInput
              label="Location"
              value={location}
              name="Key"
              onChange={(e) => setLocation(e.target.value)}
            />
            <Typography sx={{ color: "#0088AB", fontSize: "12px", px: 1 }}>
              Note : Provide the link from the embbed map section
            </Typography>
          </Grid>
          {/* Media File Upload */}
          <Grid item xs={12}>
            Media
            <Box display="flex" flexDirection="column" gap={2} pb={2}>
              <Box display="flex" gap={1} flexDirection={"column"}>
                <Button
                  sx={{
                    color: "#1B202F",
                    background: "#F6F6F6",
                    "&:hover": { background: "#F6F6F6" },
                    maxWidth: 200,
                  }}
                  component="label"
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<FileUpload style={{ color: "#1B202F" }} />}
                >
                  <Typography
                    sx={{
                      color: "#1B202F",
                      minWidth: 140,
                      maxWidth: 180,
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Upload Images
                  </Typography>
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    style={{ display: "none" }}
                    onChange={onImageUpload}
                  />
                </Button>
                {sizeError ? (
                  <Typography variant="caption" color="red">
                    {sizeError}
                  </Typography>
                ) : (
                  <Typography variant="caption" color="#4b5563">
                    Maximum image size is 2MB.
                  </Typography>
                )}
                <Typography variant="caption" color="#0088AB" px={1}>
                  Upload an image that's under 2MB with a resolution of 1200x800
                  pixels.
                </Typography>
              </Box>

              {/* Preview uploaded and existing images */}
              {imagesPreview.length > 0 && (
                <Box display="flex" flexWrap="wrap" gap={0.5}>
                  {imagesPreview.map((image, index) => (
                    <Box key={index} position="relative">
                      <img
                        // src={image.image_path || image}
                        src={
                          image.image_path
                            ? image.image_path
                            : `${API_ENDPOINT_IMG}${image}`
                        }
                        alt={image.image_name || `Image ${index + 1}`}
                        style={{
                          width: "140px",
                          height: "100px",
                          objectFit: "fill",
                          borderRadius: "8px",
                        }}
                      />
                      <IconButton
                        onClick={() => resetImage(index)}
                        sx={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          padding: "2px",
                        }}
                      >
                        <CloseSharp
                          style={{ color: "red", fontSize: "16px" }}
                        />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
            {/* <Box display="flex" gap={1} alignItems="center" pb={2}>
              <Button
                sx={{
                  color: "#1B202F",
                  background: "#F6F6F6",
                  "&:hover": {
                    background: "#F6F6F6",
                  },
                }}
                component="label"
                variant="contained"
                tabIndex={-1}
                startIcon={<FileUpload style={{ color: "#1B202F" }} />}
              >
                <Typography
                  sx={{
                    color: "#1B202F",
                    minWidth: 140,
                    maxWidth: 180,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {mediaName ? mediaName : "Upload Image"}
                </Typography>
                <input
                  type="file"
                  accept="image/*,video/*"
                  style={{ display: "none" }}
                  onChange={onMediaUpload}
                />
              </Button>

              {mediaName && (
                <IconButton onClick={resetMedia}>
                  <CloseSharp style={{ color: "#1B202F" }} />
                </IconButton>
              )}
              {mediaUrl && (
                <a href={mediaUrl} target="_blank" rel="noopener noreferrer">
                  <Button variant="outlined">View</Button>
                </a>
              )}
            </Box> */}
          </Grid>
          <Grid item xs={12} md={12} pb={2} maxWidth={400}>
            Description
            <ReactQuill
              theme="snow"
              value={description}
              onChange={handleQuillChange}
            />
            <Box display={"flex"} justifyContent={"space-between"}>
              {error ? (
                <Typography variant="caption" color="red">
                  {error}
                </Typography>
              ) : (
                <Typography variant="caption" color="#4b5563">
                  Maximum 3000 characters.
                </Typography>
              )}
              <Typography variant="caption" color="#4b5563">
                {textLength}/3000
              </Typography>
            </Box>
            {/* {error ? (
              <Typography variant="caption" color="red">
                {error}
              </Typography>
            ) : (
              <Typography variant="caption" color="#4b5563">
                Maximum 3000 characters.
              </Typography>
            )}{" "} */}
          </Grid>
        </GridContainer>
      </CDrawer>
      {/* main drawer end  */}

      {/* view drawer  */}
      <Drawer
        anchor={"right"}
        open={openViewDrawer}
        onClose={() => setOpenViewDrawer(false)}
        title={title}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            py: 1,
            borderBottom: "1px solid #3636363b",
          }}
        >
          <IconButton onClick={() => setOpenViewDrawer(false)} size="small">
            <ArrowBack style={{ color: "black" }} />
          </IconButton>
          <Typography
            variant="h6"
            className="nunito_font"
            style={{
              fontSize: "18px",
              fontWeight: 700,
              color: "#185AA6",
            }}
          >
            View {title} details
          </Typography>
        </Box>
        <Box sx={{ width: 400, p: 2 }}>
          <GridContainer>
            <Grid item xs={12} display={"flex"} flexDirection={"column"}>
              <Typography sx={{ color: "gray" }}>When</Typography>
              <Typography
                variant="h5"
                component="h2"
                sx={{ px: 1.5, fontSize: "12px" }}
                textTransform={"capitalize"}
              >
                {singleData?.when}
              </Typography>
            </Grid>
            <Grid item xs={12} display={"flex"} flexDirection={"column"}>
              <Typography sx={{ color: "gray" }}>Where</Typography>
              <Typography
                variant="h5"
                component="h2"
                sx={{ px: 1.5, fontSize: "12px" }}
                textTransform={"capitalize"}
              >
                {singleData?.where}
              </Typography>
            </Grid>
            <Grid item xs={12} display={"flex"} flexDirection={"column"}>
              <Typography sx={{ color: "gray" }}>Description</Typography>
              <Typography
                variant="h5"
                component="h2"
                sx={{ px: 1.5, fontSize: "12px" }}
                textTransform={"capitalize"}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizedDescription,
                  }}
                />
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ borderRadius: "12px" }}>
              <Typography sx={{ color: "gray", pb: 2 }}>Images</Typography>
              {singleData?.media?.length > 0 ? (
                <Grid container spacing={1.5}>
                  {singleData.media.map((item, index) => (
                    <Grid item xs={6} key={index}>
                      <img
                        src={`${API_ENDPOINT_IMG}${item}`}
                        alt="Media content"
                        style={{
                          height: 100,
                          borderRadius: "6px",
                          width: "100%", // Ensure image fills the grid item
                          objectFit: "fill", // Maintain aspect ratio
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              ) : null}
            </Grid>
          </GridContainer>
        </Box>
      </Drawer>
      {/* view drawer end  */}

      {/* view drawer  */}
      <CDrawer
        open={openFeeDrawer}
        close={() => setOpenFeeDrawer(false)}
        title={"Event Fee"}
        loading={isButtonDisabled}
        onSave={handleFeeSubmit}
        buttonName={"Set"}
      >
        <Box sx={{ width: 400 }}>
          <Box>
            <CInput
              label={"Event Fees"}
              value={eventFee}
              onChange={(e) => setEventFee(e.target.value)}
            />
          </Box>
        </Box>
      </CDrawer>
      {/* view drawer end  */}

      <AlertDialog
        onsubmit={handleActiveChange}
        open={openActive}
        handleClose={() => setOpenActive(false)}
        text={`Are you sure want to ${
          singleData?.activeStatus == 1 ? "Active" : "Inactive"
        } ?`}
      ></AlertDialog>
      <AlertDialog
        onsubmit={handleActiveChange}
        open={openMulitiStatus}
        handleClose={() => setOpenMultistatus(false)}
        text={`Are you sure want to change status of ${selectedId.length} items?`}
      ></AlertDialog>
      <AlertDialog
        onsubmit={handleDelete}
        open={openDel}
        handleClose={() => setOpenDel(false)}
        text={"Are you sure want to delete ?"}
      ></AlertDialog>
      <AlertDialog
        onsubmit={handleDelete}
        open={openMulitiDelete}
        handleClose={() => setOpenMultiDelete(false)}
        text={`Are you sure want to delete ${selectedId.length} items?`}
      ></AlertDialog>
      {isAlertVisible && (
        <AutoHideAlert
          severity={alertSeverity}
          message={alertMessage}
          autoHideDuration={3000}
          open={isAlertVisible}
          onClose={() => setIsAlertVisible(false)}
        />
      )}
    </>
  );
}
