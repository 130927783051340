import React, { useState, useEffect, useRef } from "react";
import MasterTable from "../../Components/MasterTable";
import { GridActionsCellItem } from "@mui/x-data-grid";
import {
  Add,
  CloseOutlined,
  MoreVert,
  Close,
  ArrowBack,
  RefreshOutlined,
} from "@mui/icons-material";
import CDrawer from "../../Components/Container/CusDrawer";
import { axiosGet, axiosPost, axiosPostForm } from "../../lib/api";
import GridContainer from "../../Components/Container/GridContainer";
import Cookies from "js-cookie";
import {
  Box,
  Menu,
  MenuItem,
  Grid,
  Stack,
  FormControl,
  InputLabel,
  Select,
  Chip,
  IconButton,
  TextField,
  capitalize,
  Drawer,
  Typography,
  Collapse,
  Badge,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Radio,
} from "@mui/material";
import CreateButton from "../../Components/buttons/CreateButton";
import CInput from "../../Components/Container/CInput";
import AutoHideAlert from "../../Components/Container/AutoHideAlert";
import AlertDialog from "../../Components/Container/AlertDialog";
import CAlertDialog from "../../Components/Container/CAlertDialog";
import SearchFilter from "../../Components/buttons/SearchFilter";
import FilterButton from "../../Components/buttons/FilterButton";
import { API_ENDPOINT, capitalizeText } from "../../lib/config";

const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      // width: 160,
    },
  },
};

export default function Panelist() {
  const ACCESS_TOKEN = Cookies.get("spectransyscore_token");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openViewDrawer, setOpenViewDrawer] = useState(false);
  const [openActive, setOpenActive] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [data, setData] = useState([]);
  const [isAll, setIsAll] = useState(false);
  const [sortField, setSortField] = useState("createdDate");
  const [sortOrder, setSortOrder] = useState("descending");
  const [limit, setLimit] = useState(15);
  const [pageNumber, setPageNumber] = useState(1);
  const [dataCount, setdataCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [openDrawerType, setOpenDrawerType] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [singleData, setSingleData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filtersList, setfiltersList] = useState(false);
  const [isStatusSelected, setIsStatusSelected] = useState(false);
  const [isAOESelected, setIsAOESelected] = useState(false);
  const [statusFilter, setStatusFilter] = useState("");
  const [AOEFilter, setAOEFilter] = useState("");
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [emailError, setEmailError] = useState("");
  const [rowBtn, setRowBtn] = useState(false);
  const [selectedId, setSelectedId] = useState([]);
  const [multistatusValue, setMultistatusValue] = useState("");
  const [openMulitiStatus, setOpenMultistatus] = useState(false);
  const [openMulitiDelete, setOpenMultiDelete] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [country, setCountry] = useState("");
  const [organization, setOrganization] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [picture, setPicture] = useState(null);
  const [pictureUrl, setPictureUrl] = useState("");
  const [pictureName, setPictureName] = useState("");
  const fileInputRef = useRef(null);
  const open = Boolean(anchorEl);
  const areCheckboxesEnabled = true;
  const [AOE, setAOE] = useState("4");

  // Function to get selected row data
  const getSelectedRowsData = () => {
    return rows.filter((row) => rowSelectionModel.includes(row.id));
  };

  useEffect(() => {
    const selectedRowsData = getSelectedRowsData();
    const selectedDatauniqids = selectedRowsData.map((row) => row.fullData);
    const cusID = selectedDatauniqids.map((row) => row.dataUniqId);
    setSelectedId(cusID);
    if (cusID.length > 0) {
      setRowBtn(true);
    } else {
      setRowBtn(false);
    }
    // let cusId=selectedDatauniqids.filter((data)=>data._id)
  }, [rowSelectionModel]);

  const handleSortModelChange = (model) => {
    if (model.length > 0) {
      const { field, sort } = model[0];
      setSortField(field);
      setSortOrder(sort == "asc" ? "ascending" : "descending");
    } else {
      // Reset to default sorting if needed
      setSortField("createdDate");
      setSortOrder("descending");
    }
  };

  const maxSizeInMB = 2;
  const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
  const [sizeError, setSizeError] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > maxSizeInBytes) {
        setSizeError(
          `File size exceeds ${maxSizeInMB} MB. Please select a smaller file.`
        );
        // setIsButtonDisabled(true);
        return;
      }
      // setIsButtonDisabled(false);
      setSizeError("");
      setPicture(file);
      setPictureUrl(URL.createObjectURL(file));
      setPictureName(file.name);
    }
  };

  const resetFileInput = () => {
    setPicture('');
    setPictureUrl("");
    setPictureName("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };


  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    if (value === "") {
      setEmailError(""); // Clear error if input is empty
    } else if (!validateEmail(value)) {
      setEmailError("Invalid email format");
    } else {
      setEmailError("");
    }
  };
  // const handleMobileChange = (e) => {
  //   const value = e.target.value;
  //   // if (/^\d*$/.test(value)) {
  //   if (/^\d{0,10}$/.test(value)) {
  //     setMobileNo(value);
  //     if (value.length === 10) {
  //       setMobileError("");
  //       // }
  //     } else if (value.length > 4) {
  //       setMobileError("Invalid mobile number");
  //     }
  //   }
  // };
  const handleMobileChange = (e) => {
    const value = e.target.value;
    // if (/^\d*$/.test(value)) {
    if (/^\+?\d*$/.test(value)) {
      setMobileNo(value);
      const digitCount = (value.match(/\d/g) || []).length;
      if (digitCount >= 10 || digitCount <= 5) {
        setMobileError("");
        // }
      } else if (digitCount > 4) {
        setMobileError("Invalid mobile number");
      }
    }
  };

  // Function to update searchValue when input changes
  const handleSearchInputChange = (input) => {
    setPageNumber(1);
    setSearchValue(input);
  };

  const handlefilterBadgeVisible = () => {
    if (isStatusSelected || isAOESelected) {
      // setFilterBadgeVisible(true);
      return true;
    } else {
      // setFilterBadgeVisible(false);
      return false;
    }
  };

  // Toggel filter component
  const HandleChangeFilter = () => {
    setfiltersList(!filtersList);
    // if (filtersList === true) {
    //   setIsIndusSelected(false);
    //   setIsRoleSelected(false);
    //   setIsStatusSelected(false);
    //   setIsAppStatusSelected(false);
    //   setIsWebStatusSelected(false);
    //   setAppStatusFilter(3);
    //   setStatusFilter(3);
    // }
  };

  const handleRefresh = () => {
    setSearchValue("");
    setIsStatusSelected(false);
    setIsAOESelected(false);
    setStatusFilter("");
    setAOEFilter("");
    fetchData();
  };

  // Toggel Drawer
  const toggleDrawer = (newOpen, type) => () => {
    // type 1 : Create
    // type 2 : Edit
    setOpenDrawer(newOpen);
    setOpenDrawerType(type);
    handleCloseMenu();
    if (type === 1) {
      setFirstName("");
      setLastName("");
      setEmail("");
      setMobileNo("");
      setCountry("");
      setAOE("4");
      setPicture(null);
      setPictureUrl("");
      setOrganization("");
      setJobTitle("");
      setIsButtonDisabled(false);
    } else {
      setFirstName(singleData.firstName);
      setLastName(singleData.lastName);
      setEmail(singleData.email);
      setMobileNo(singleData.phoneNumber);
      setCountry(singleData.country);
      setAOE(singleData.areaOfExpertise);
      setPicture(singleData.photo);
      setPictureUrl(singleData.photo);
      // setPicture(
      //   singleData.photo
      //     ? singleData.photo === `${API_ENDPOINT}undefined`
      //       ? singleData.photo
      //       : null
      //     : null
      // );
      // setPictureUrl(
      //   singleData.photo
      //     ? singleData.photo === `${API_ENDPOINT}undefined`
      //       ? singleData.photo
      //       : ""
      //     : ""
      // );
      setOrganization(singleData.organization);
      setJobTitle(singleData.jobTitle);
      setIsButtonDisabled(false);
    }
  };


  const handleSaveClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    const fullData = item.fullData;
    setSingleData(fullData);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // Open Status change warning box
  const handleStatusOpen = () => {
    setOpenActive(true);
    handleCloseMenu();
  };

  // Open Status change warning box
  const handleViewOpen = () => {
    setOpenViewDrawer(true);
    handleCloseMenu();
    setPictureUrl(singleData.photo)
  };

  const handleDeleteOpen = () => {
    setOpenDel(true);
    handleCloseMenu();
  };
  const handleMultiDelete = () => {
    setOpenMultiDelete(true);
  };
  // open multi status waring
  const handleMultiStatus = (event) => {
    setOpenMultistatus(true);
    setMultistatusValue(event.target.value);
  };

  // Handler to log the page number on page change
  const handlePageChange = (model, newPage) => {
    let page = model.page + 1;
    let pageSize = model.pageSize;
    setPageNumber(page);
    setLimit(pageSize);
  };

  const handlePageSizeChange = (newPageSize) => {
    setLimit(newPageSize);
    setPageNumber(0);
  };

  // Filter Functions Start
  const handleStatusType = (value) => {
    setPageNumber(1);
    setStatusFilter(value);
    setIsStatusSelected(true);
    // handlefilterBadgeVisible(true)
  };
  const handleAOEType = (value) => {
    setPageNumber(1);
    setAOEFilter(value);
    setIsAOESelected(true);
    // handlefilterBadgeVisible(true)
  };
  // Filter Functions End

  const fetchData = async () => {
    setIsLoading(true);
    axiosGet
      .get(
        `panelists/get/admin?userToken=${ACCESS_TOKEN}&page=${pageNumber}&limit=${limit}&isAll=${isAll}&sortField=${sortField}&sortOrder=${sortOrder}&query=${searchValue}&activeStatus=${
          statusFilter == 3 ? "" : statusFilter
        }&areaOfExpertise=${AOEFilter == 0 ? "" : AOEFilter}`
        // AOEFilter
      )
      // &activeStatus=${statusFilter == 3 ?"":statusFilter}
      .then((response) => {
        if (response.data.message === "Panelists retrieved successfully") {
          setData(response.data.data);
          setdataCount(response.data.totalItems);
          setPageNumber(pageNumber === 0 ? 1 : pageNumber);
          setPageCount(response.data.total_pages);
          setIsLoading(false);
          // setIsAlertVisible(true);
          // setAlertSeverity("success");
          // setAlertMessage("response.data.error");
        } else {
          setIsLoading(false);
          setIsAlertVisible(true);
          setAlertSeverity("error");
          setAlertMessage("Something went wrong.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(true);
      });
  };

  useEffect(() => {
    fetchData();
  }, [
    pageNumber,
    limit,
    searchValue,
    statusFilter,
    AOEFilter,
    sortField,
    sortOrder,
  ]);


  

  // Funtion for create new data or edit existing data
  const handleSubmit = () => {
    setIsButtonDisabled(true);
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
    setIsLoading(true);

    const formData = new FormData();
    formData.append("userToken", ACCESS_TOKEN);
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("email",email);
    formData.append("phoneNumber", mobileNo);
    formData.append("country", country);
    formData.append("jobTitle", jobTitle);
    formData.append("organization", organization);
    formData.append("areaOfExpertise", AOE);
    if (openDrawerType == 1) {
      formData.append("photo", picture);
    } else if (openDrawerType == 2) {
      formData.append("dataUniqId", singleData?.dataUniqId);

      const photoValue =
        picture === singleData.photo ? singleData.existingPhotoPath : picture;

      if (typeof photoValue === "string") {
        formData.append("existingPhotoPath", photoValue);
      } else {
        formData.append("photo", photoValue);
      }
    }
    try {
      if (openDrawerType == 1) {
        axiosPostForm
          .post(`panelists/create`, formData)
          .then((response) => {
            // Handle the successful POST response here
            // if (response.data.action === "success") {
            // If response data action is 200, show the alert
            setIsAlertVisible(true);
            setAlertSeverity("success");
            setAlertMessage(response.data.message);
            // Cookies.remove("uuid");
            setOpenDrawer(false);
            setIsLoading(false);
            fetchData();
            setIsButtonDisabled(false);
            // } else {
            //   setIsAlertVisible(true);
            //   setAlertSeverity("error");
            //   setIsLoading(false);
            //   setAlertMessage(response.data.message);
            // }
          })
          .catch((error) => {
            // Handle POST errors here
            console.error("POST Error:", error);
            setIsAlertVisible(true);
            setAlertSeverity("error");
            setIsLoading(false);
            setIsButtonDisabled(false);
            setAlertMessage(error?.response.data.error);
          });
      } else {
        axiosPostForm
          .put(`panelists/update`, formData)
          .then((response) => {
            // Handle the successful POST response here
            if (response.data.message === "Panelist updated successfully") {
              // If response data action is 200, show the alert
              setIsAlertVisible(true);
              setAlertSeverity("success");
              setAlertMessage(response.data.message);
              // Cookies.remove("uuid");
              setOpenDrawer(false);
              setIsLoading(false);
              fetchData();
              setIsButtonDisabled(false);
            } else {
              setIsAlertVisible(true);
              setAlertSeverity("error");
              setIsLoading(false);
              setAlertMessage(response.error);
              setIsButtonDisabled(false);
            }
          })
          .catch((error) => {
            // Handle POST errors here
            console.error("POST Error:", error);
            setIsAlertVisible(true);
            setAlertSeverity("error");
            setIsLoading(false);
            setIsButtonDisabled(false);
            setAlertMessage(error?.response.data.error);
          });
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setIsButtonDisabled(false);
    }
  };

  

  // Funtion for change status of single data
  const handleActiveChange = () => {
    setIsLoading(true);
    const jsonData = {
      userToken: ACCESS_TOKEN,
      // dataUniqIds: [singleData.dataUniqId],
      dataUniqIds:
        selectedId.length > 0 ? selectedId : [singleData?.dataUniqId],
      activeStatus: multistatusValue || singleData.activeStatus === 1 ? 0 : 1,
    };
    axiosPost
      .post(`panelists/change/activestatus`, jsonData)
      .then((response) => {
        if (response.data.message === "Panelist records updated successfully") {
          // setEffectToggle(!effectToggle);
          // setSelectedItems([]);
          // setActionData("");
          setAlertMessage("Updated successfully.");
          setIsAlertVisible(true);
          setAlertSeverity("success");
          handleCloseMenu();
          setIsLoading(false);
          fetchData();
          setSelectedId("");
          setMultistatusValue("");
          setRowSelectionModel("");
        }
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error:", error);
      });
  };

  // Funtion for delete single data
  const handleDelete = () => {
    setIsLoading(true);
    const jsonData = {
      userToken: ACCESS_TOKEN,
      dataUniqIds: selectedId.length > 0 ? selectedId : [singleData.dataUniqId],
    };
    axiosPost
      .delete(`panelists/delete`, { data: jsonData })
      .then((response) => {
        // if (response.data.message === "Attendees deleted successfully") {
        // setEffectToggle(!effectToggle);
        handleCloseMenu();
        setAlertMessage("Deleted successfully.");
        setIsAlertVisible(true);
        setAlertSeverity("success");
        setIsLoading(false);
        fetchData();
        setSelectedId("");
        setMultistatusValue("");
        setRowSelectionModel("");
        // }
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error:", error);
      });
  };

  const columns = [
    // {
    //   field: "id",
    //   headerName: "#",
    //   flex: 1,
    //   width: 60,
    //   maxWidth: 80,
    //   // disableColumnMenu: true,
    //   headerClassName: "super-app-theme--header",
    // },
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
      minWidth: 100,
      // disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
      minWidth: 100,
      // disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "jobTitle",
      headerName: "Job",
      flex: 1,
      minWidth: 100,
      // disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "areaOfExpertise",
      headerName: "Area of expertise",
      flex: 1,
      minWidth: 100,
      // disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "organization",
      headerName: "Organization",
      flex: 1,
      minWidth: 100,
      maxWidth: 200,
      // disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "country",
      headerName: "Country",
      flex: 1,
      maxWidth: 200,
      minWidth: 100,
      // disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      maxWidth: 200,
      minWidth: 100,
      // disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        const status = params.value; // 'app' or 'rej'
        const style = {
          color:
            status === "Inactive"
              ? "#FFA500"
              : status === "Active"
              ? "#008000"
              : null,
        };

        return <span style={style}>{status}</span>;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      // disableColumnMenu: true,
      flex: 1,
      maxWidth: 100,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <GridActionsCellItem
          icon={<MoreVert />}
          label="Save"
          sx={{ position: "relative", color: "primary.main" }}
          onClick={(event) => handleSaveClick(event, params.row)}
        />
      ),
    },
  ];

  const rows = data?.map((item, index) => ({
    id: index + 1,
    firstName: capitalizeText(item.firstName),
    lastName: capitalizeText(item.lastName),
    jobTitle: capitalizeText(item.jobTitle),
    areaOfExpertise: capitalizeText(
      item.areaOfExpertise === "1"
        ? "Conditional Monitoring"
        : item.areaOfExpertise === "2"
        ? "Reliability"
        : item.areaOfExpertise === "3"
        ? "Conditional Monitoring & Reliability"
        : "---"
    ),
    organization: capitalizeText(item.organization),
    country: capitalizeText(item.country),
    status:
      item.activeStatus === 0
        ? "Inactive"
        : item.activeStatus === 1
        ? "Active"
        : null,
    fullData: item,
  }));

  const title = "Panelist";
  const boxSx = {
    "& .super-app-theme--header": {
      backgroundColor: "#0088AB",
      color: "white",
      fontSize: "16px",
      fontWeight: "bold",
    },
    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
      {
        backgroundColor: "#0088AB",
      },
    "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
      color: "white",
    },
    "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root.Mui-checked": {
      color: "white",
    },
  };

  const FilterComponent = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 1,
        }}
      >
        <IconButton onClick={handleRefresh} size="small">
          <RefreshOutlined />
        </IconButton>

        {/* <FormControl size="small" sx={{ minWidth: "150px" }}>
          <InputLabel sx={{ fontSize: "14px" }} id="game">
            {"Area of Expertise"}
          </InputLabel>
          <Select
            MenuProps={MenuProps}
            sx={{
              fontSize: "14px",
              height: "34px",
              bgcolor: isAOESelected ? "#185aa617" : undefined,
            }}
            placeholder={"Area of Expertise"}
            labelId="status"
            id="status"
            label={"Area of Expertise"}
            value={AOEFilter}
            onChange={(e) => handleAOEType(e.target.value)}
          >
            <MenuItem sx={{ fontSize: "14px" }} value={0}>
              All
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={1}>
            Conditional Monitoring
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={2}>
              Reliability
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={3}>
              Both
            </MenuItem>
          </Select>
        </FormControl> */}
        <FormControl size="small" sx={{ minWidth: "150px" }}>
          <InputLabel sx={{ fontSize: "14px" }} id="game">
            {"Status"}
          </InputLabel>
          <Select
            MenuProps={MenuProps}
            sx={{
              fontSize: "14px",
              height: "34px",
              bgcolor: isStatusSelected ? "#185aa617" : undefined,
            }}
            placeholder={"Status"}
            labelId="status"
            id="status"
            label={"Status"}
            value={statusFilter}
            onChange={(e) => handleStatusType(e.target.value)}
          >
            <MenuItem sx={{ fontSize: "14px" }} value={3}>
              All
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={1}>
              Active
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={0}>
              Inactive
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
    );
  };

  return (
    <>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        flexDirection={{ xs: "column", md: "row" }}
      >
        <CreateButton
          heading={title}
          pagecount={dataCount}
          onAddClick={toggleDrawer(true, 1)}
        />
        <Box display={"flex"} alignItems={"center"} gap={1.5}>
          {" "}
          <SearchFilter
            onSearchButtonClick={handleSearchInputChange}
            searchValue={searchValue}
          />
          <Badge
            color="secondary"
            variant="dot"
            invisible={!handlefilterBadgeVisible()}
          >
            <FilterButton
              HandleChangeFilter={HandleChangeFilter}
              filtersList={filtersList}
            />
          </Badge>
        </Box>
      </Box>

      <Collapse in={filtersList} timeout="auto" unmountOnExit>
        <Box sx={{ display: "flex", justifyContent: "end", py: 0.5 }}>
          {FilterComponent()}
        </Box>
      </Collapse>
      <Box sx={{ overflow: "auto" }}>
        <MasterTable
          rows={rows}
          columns={columns}
          areCheckboxesEnabled={areCheckboxesEnabled}
          title={title}
          limit={limit}
          loading={isLoading}
          boxSx={boxSx}
          handlePageChange={handlePageChange}
          handlePageSizeChange={handlePageSizeChange}
          rowCount={dataCount}
          paginationModel={{ pageNumber, limit }}
          handleMultiDelete={handleMultiDelete}
          handleMultiStatus={handleMultiStatus}
          multistatusValue={multistatusValue}
          setRowSelectionModel={setRowSelectionModel}
          rowBtn={rowBtn}
          rowSelectionModel={rowSelectionModel}
          handleSortModelChange={handleSortModelChange}
        />
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={toggleDrawer(true, 2)} sx={{ width: 140 }}>
          Edit
        </MenuItem>
        <MenuItem onClick={handleViewOpen}>View</MenuItem>
        <MenuItem onClick={handleStatusOpen}>
          Mark as {singleData.activeStatus === 0 ? "active" : "inactive"}
        </MenuItem>
        <MenuItem onClick={handleDeleteOpen}>Delete</MenuItem>
      </Menu>

      {/* main drawer  */}
      <CDrawer
        open={openDrawer}
        close={() => setOpenDrawer(false)}
        title={title}
        loading={isButtonDisabled}
        buttonName={openDrawerType === 1 ? "Create" : "Save" }
        openType={openDrawerType}
        onSave={handleSubmit}
      >
        {/* Content for CDrawer */}
        <GridContainer spacing={2}>
          <Grid item xs={12} display={"flex"} gap={3}>
            <Stack>
              {/* <Typography variant="button" color="initial">
              Choose Profile Picture
            </Typography> */}
              <Stack>
                {pictureUrl ? (
                  <div
                    style={{
                      width: "170px",
                      height: "170px",
                      border: "2px dashed #ccc",
                      borderRadius: "10px",
                      padding: "4px",
                      textAlign: "center",
                      position: "relative",
                      display: "grid",
                      placeItems: "center",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "10px",
                      }}
                      src={pictureUrl}
                      alt={pictureName}
                    />
                    <IconButton
                      sx={{
                        padding: 0.5,
                        px: 1,
                        position: "absolute",
                        top: "4px",
                        right: "4px",
                        background: "#f44336",
                      }}
                      onClick={resetFileInput}
                    >
                      <CloseOutlined
                        sx={{ color: "#fff", width: 16, aspectRatio: 1 / 1 }}
                      />
                    </IconButton>
                  </div>
                ) : (
                  <>
                    <label htmlFor="image-upload">
                      <div
                        style={{
                          // aspectRatio: "4/3",
                          width: "170px",
                          height: "170px",
                          // height: "130px",
                          // height: "146px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "2px dashed #ccc",
                          borderRadius: "10px",
                          padding: "8px",
                          textAlign: "center",
                        }}
                      >
                        <Box
                          component="img"
                          sx={{
                            // height: "138px",
                            width: "110px",
                          }}
                          alt="Placeholder"
                          src="/images/upload500.svg"
                        />
                      </div>
                      <input
                        id="image-upload"
                        type="file"
                        ref={fileInputRef}
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                      />
                    </label>
                    {sizeError ? (
                      <Typography variant="caption" color="red">
                        {sizeError}
                      </Typography>
                    ) : (
                      <Typography variant="caption" color="#4b5563">
                        Maximum image size is 2MB.
                      </Typography>
                    )}
                  </>
                )}
              </Stack>
            </Stack>
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              width={"100%"}
            >
              <Box display={"flex"} gap={1} width={"100%"}>
                <CInput
                  label="First Name"
                  value={firstName}
                  name="firstName"
                  onChange={(e) => setFirstName(e.target.value)}
                />

                <CInput
                  label="Last Name"
                  value={lastName}
                  name="Last Name"
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Box>
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={0.5}
                width={"100%"}
              >
                <Box>
                  <CInput
                    label="Email"
                    value={email}
                    name="Email"
                    onChange={handleEmailChange}
                  />
                  {emailError && (
                    <Typography
                      sx={{ fontSize: "12px", color: "#FF0000", px: 0.5 }}
                    >
                      {emailError}
                    </Typography>
                  )}
                </Box>
                <Box display={"flex"} gap={1} width={"100%"}>
                  <Box sx={{ width: "50%" }}>
                    <CInput
                      label="Mobile No."
                      value={mobileNo}
                      name="Mobile No."
                      onChange={handleMobileChange}
                      // onChange={(e) => setMobileNo(e.target.value)}
                    />
                    {mobileError && (
                      <Typography
                        sx={{
                          fontSize: "12px",
                          color: "#FF0000",
                          px: 0.5,
                          pb: 1,
                        }}
                      >
                        {mobileError}
                      </Typography>
                    )}
                  </Box>
                  <Box sx={{ width: "50%" }}>
                    <CInput
                      label="Country"
                      value={country}
                      name="country"
                      onChange={(e) => setCountry(e.target.value)}
                    />
                  </Box>
                </Box>
                <Box display={"flex"} gap={1} width={"100%"}>
                  <Box sx={{ width: "50%" }}>
                    <CInput
                      label="Job Title"
                      value={jobTitle}
                      name="jobTitle"
                      onChange={(e) => setJobTitle(e.target.value)}
                    />
                  </Box>
                  <Box sx={{ width: "50%" }}>
                    <CInput
                      label="Organization"
                      value={organization}
                      name="organization"
                      onChange={(e) => setOrganization(e.target.value)}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>

          {/* <Grid item xs={12} md={6}>
            <CInput
              label="Job Title"
              value={jobTitle}
              name="jobTitle"
              onChange={(e) => setJobTitle(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CInput
              label="Organization"
              value={organization}
              name="organization"
              onChange={(e) => setOrganization(e.target.value)}
            />
          </Grid> */}
          <Grid item xs={12}>
            {/* <FormControl size="small" fullWidth>
              <InputLabel sx={{ fontSize: "14px" }} id="game">
                {"Area of Expertise"}
              </InputLabel>
              <Select
                MenuProps={MenuProps}
                sx={{
                  fontSize: "14px",
                  height: "34px",
                }}
                placeholder={"Area of Expertise"}
                label={"Area of Expertise"}
                value={AOE}
                onChange={(e) => setAOE(e.target.value)}
              >
                {AOEData.map((data, index) => (
                  <MenuItem sx={{ fontSize: "14px" }} value={data._id}>
                    {data.areaOfExpertise}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
            <FormControl component="fieldset">
              <FormLabel component="legend" sx={{ fontSize: "14px" }}>
                Area of Expertise
              </FormLabel>
              <RadioGroup
                row
                aria-label="area-of-interest"
                name="area-of-interest"
                value={AOE}
                onChange={(e) => setAOE(e.target.value)}
              >
                <FormControlLabel
                  value="1"
                  control={<Radio size="small" />}
                  label={
                    <span style={{ fontSize: "14px" }}>
                      Conditional Monitoring
                    </span>
                  }
                  sx={{ fontSize: "12px" }}
                />
                <FormControlLabel
                  value="2"
                  control={<Radio size="small" />}
                  label={<span style={{ fontSize: "14px" }}>Reliability</span>}
                />
                <FormControlLabel
                  value="3"
                  control={<Radio size="small" />}
                  label={<span style={{ fontSize: "14px" }}>Both</span>}
                />
                <FormControlLabel
                  value="4"
                  control={<Radio size="small" />}
                  label={<span style={{ fontSize: "14px" }}>None</span>}
                />
              </RadioGroup>
            </FormControl>
            {/* <FormControl component="fieldset">
              <FormLabel component="legend">Area of Expertise</FormLabel>
              <RadioGroup
                row
                aria-label="area-of-interest"
                name="area-of-interest"
                value={AOE}
                onChange={(e) => setAOE(e.target.value)}
              >
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="Conditional Monitoring"
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="Reliability"
                />
                <FormControlLabel value="3" control={<Radio />} label="Both" />
              </RadioGroup>
            </FormControl> */}
          </Grid>
        </GridContainer>
      </CDrawer>
      {/* main drawer end  */}

      {/* view drawer  */}
      <Drawer
        anchor={"right"}
        open={openViewDrawer}
        onClose={() => setOpenViewDrawer(false)}
        title={title}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            py: 1,
            borderBottom: "1px solid #3636363b",
          }}
        >
          <IconButton onClick={() => setOpenViewDrawer(false)} size="small">
            <ArrowBack style={{ color: "black" }} />
          </IconButton>
          <Typography
            variant="h6"
            className="nunito_font"
            style={{
              fontSize: "18px",
              fontWeight: 700,
              color: "#185AA6",
            }}
          >
            View {title}
          </Typography>
        </Box>
        <Box sx={{ width: 400, p: 2 }}>
          <GridContainer>
          <Grid item xs={12} display={"flex"} justifyContent={'center'}>
              {pictureUrl ? (
                <div
                  style={{
                    // aspectRatio: "4/3",
                    width: "170px",
                    height: "170px",
                    // height: "146px",
                    border: "2px dashed #ccc",
                    borderRadius: "100%",
                    padding: "4px",
                    textAlign: "center",
                    position: "relative",
                    display: "grid",
                    placeItems: "center",
                    overflow: "hidden",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "100%",
                    }}
                    src={pictureUrl}
                    alt={pictureName}
                  />
                </div>
              ) : null}
            </Grid>
            <Grid item xs={12} display={"flex"} flexDirection={"column"}>
              <Typography sx={{ color: "gray" }}>First Name </Typography>
              <Typography
                variant="h5"
                component="h2"
                sx={{ px: 1.5, fontSize: "12px" }}
                textTransform={"capitalize"}
              >
                {singleData.firstName}
              </Typography>
            </Grid>
            <Grid item xs={12} display={"flex"} flexDirection={"column"}>
              <Typography sx={{ color: "gray" }}>Last Name</Typography>
              <Typography
                variant="h5"
                component="h2"
                sx={{ px: 1.5, fontSize: "12px" }}
                textTransform={"capitalize"}
              >
                {singleData.lastName}
              </Typography>
            </Grid>
            <Grid item xs={12} display={"flex"} flexDirection={"column"}>
              <Typography sx={{ color: "gray" }}>Job</Typography>
              <Typography
                variant="h5"
                component="h2"
                sx={{ px: 1.5, fontSize: "12px" }}
                textTransform={"capitalize"}
              >
                {singleData?.jobTitle}
              </Typography>
            </Grid>
            <Grid item xs={12} display={"flex"} flexDirection={"column"}>
              <Typography sx={{ color: "gray" }}>Organization</Typography>
              <Typography
                variant="h5"
                component="h2"
                sx={{ px: 1.5, fontSize: "12px" }}
                textTransform={"capitalize"}
              >
                {singleData?.organization}
              </Typography>
            </Grid>

            <Grid item xs={12} display={"flex"} flexDirection={"column"}>
              <Typography sx={{ color: "gray" }}>Area of Expertise</Typography>
              <Typography
                variant="h5"
                component="h2"
                sx={{ px: 1.5, fontSize: "12px" }}
                textTransform={"capitalize"}
              >
                {singleData.areaOfExpertise === "1"
                  ? "Conditional Monitoring"
                  : singleData.areaOfExpertise === "2"
                  ? "Reliability"
                  : singleData.areaOfExpertise === "3"
                  ? "Conditional Monitoring & Reliability"
                  : "Not Specified"}
              </Typography>
            </Grid>

            <Grid item xs={12} display={"flex"} flexDirection={"column"}>
              <Typography sx={{ color: "gray" }}>Mobile</Typography>
              <a
                href={`tel:${singleData.phoneNumber}`}
                style={{ paddingLeft: 12, fontSize: "12px", color: "#000" }}
              >
                {singleData.phoneNumber}
              </a>
            </Grid>

            <Grid item xs={12} display={"flex"} flexDirection={"column"}>
              <Typography sx={{ color: "gray" }}>Email</Typography>
              <a
                href={`mailto:${singleData.email}`}
                style={{ paddingLeft: 12, fontSize: "12px", color: "#000" }}
              >
                {singleData.email}
              </a>
            </Grid>

            <Grid item xs={12} display={"flex"} flexDirection={"column"}>
              <Typography sx={{ color: "gray" }}>Country</Typography>
              <Typography
                variant="h5"
                component="h2"
                sx={{ px: 1.5, fontSize: "12px" }}
                textTransform={"capitalize"}
              >
                {singleData.country}
              </Typography>
            </Grid>
          </GridContainer>
        </Box>
      </Drawer>
      {/* view drawer end  */}

      <AlertDialog
        onsubmit={handleActiveChange}
        open={openActive}
        handleClose={() => setOpenActive(false)}
        text={`Are you sure want to ${
          singleData.activeStatus == 1 ? "Active" : "Inactive"
        } ?`}
      ></AlertDialog>
      <AlertDialog
        onsubmit={handleActiveChange}
        open={openMulitiStatus}
        handleClose={() => setOpenMultistatus(false)}
        text={`Are you sure want to change status of ${selectedId.length} items?`}
      ></AlertDialog>
      <AlertDialog
        onsubmit={handleDelete}
        open={openDel}
        handleClose={() => setOpenDel(false)}
        text={"Are you sure want to delete ?"}
      ></AlertDialog>
      <AlertDialog
        onsubmit={handleDelete}
        open={openMulitiDelete}
        handleClose={() => setOpenMultiDelete(false)}
        text={`Are you sure want to delete ${selectedId.length} items?`}
      ></AlertDialog>
      {isAlertVisible && (
        <AutoHideAlert
          severity={alertSeverity}
          message={alertMessage}
          autoHideDuration={3000}
          open={isAlertVisible}
          onClose={() => setIsAlertVisible(false)}
        />
      )}
    </>
  );
}
