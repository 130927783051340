"use client";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Box, Card, Typography } from "@mui/material";
import AuthLogin from "./AuthLogin";
import { axiosPost } from "../../../lib/api";
import AutoHideAlert from "../../../Components/Container/AutoHideAlert";
import Cookies from "js-cookie";
import { axiosGet } from "../../../lib/api";

const Login = () => {
  const [postError, setPostError] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const router = useNavigate();

  const token = Cookies.get("token") !== undefined;
  const ACCESS_TOKEN = Cookies.get("spectransyscore_token");
  const [loginDetails, setLoginDetails] = useState({
    user_name: "",
    show_password: "",
  });

  const [username, setUserName] = useState("");
  const [password, setpassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [alertSeverity, setAlertSeverity] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [isForgot, setisForgot] = useState(false);

  // useEffect(() => {
  //   axiosGet
  //     .get(`valid_token?user_token=${ACCESS_TOKEN}`)
  //     .then((response) => {
  //       if (response.data.action == "success") {
  //         Cookies.set("token", response.data.access_token, {
  //           expires: 7, // Set the cookie expiration (7 days in this example)
  //         });
  //         Cookies.set("user_id", response.data.user_id, {
  //           expires: 7, // Set the cookie expiration (7 days in this example)user_login
  //         });
  //         // router("/dashboard");
  //       } else {
  //         router("/login");
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });
  // }, []);

  const handleLoginDetailsChange = (fieldName, value) => {
    setLoginDetails((prevLoginDetails) => ({
      ...prevLoginDetails,
      [fieldName]: value,
    }));
  };

  const handleForgotPassword = () => {
    setIsLoading(true);
    router("/forgot_password");
  };

  const handleSignIn = (e) => {
    // e.preventDefault();
    const jsonStructure = {
      password: password,
      userName: username,
    };
    try {
      axiosPost
        .post("login/", jsonStructure)
        .then((response) => {
          if (response.data.message === "Login successful") {
            setIsAlertVisible(true);
            setAlertSeverity("success");
            setAlertMessage(response.data.message);
            Cookies.set("spectransyscore_token", response.data.accessToken, {
              expires: 7, // Set the cookie expiration (7 days in this example)
            });
            Cookies.set("spectransyscore_userName", response.data.user.userName, {
              expires: 7, // Set the cookie expiration (7 days in this example)user_login
            });
            Cookies.set("spectransyscore_userType", response.data.user.userType, {
              expires: 7, // Set the cookie expiration (7 days in this example)user_login
            });
            // setUserData(response.data);
            router("/");
            // setTimeout(() => {
            //   setIsAlertVisible(false);
            // }, 3000);
          } else {
            setPostError(response.error);
          }
        })
        .catch((error) => {
          console.error("POST Error:", error);
          let errorData = error.response.data.error;
          setAlertMessage(errorData);
          setIsAlertVisible(true);
          setAlertSeverity("error");
        });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const [passwordShown, setPasswordShown] = useState(false);

  const toggleButton = () => {
    setPasswordShown(!passwordShown);
  };

  const backgroundImageUrl = "url(/images/bg.png)";

  return (
    <>
      <Box
        sx={{
          position: "relative",
          height: { xs: "100vh", md: "100vh" },
          overflow: "hidden",
          background: "#fafafa",
        }}
      >
        <Grid
          container
          spacing={2}
          height={"auto"}
          justifyContent="center"
          sx={{ overflow: "hidden" }}
        >
          {/* <Grid
            item
            xs={12}
            sm={12}
            lg={6}
            xl={6}
            // display="flex"
            sx={{ display: { xs: "none", lg: "flex" } }}
            justifyContent="center"
            alignItems="center"
          >
            <Box
              component={"img"}
              textAlign="center"
              sx={{
                width: "100%",
                position: "relative",
              }}
              src="/images/600x400.png"
            ></Box>
          </Grid> */}
          <Grid
            item
            xs={12}
            sm={12}
            // lg={6}
            // xl={6}
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              height: "100vh",
              padding: "0px 25px !important",
              // background: "white",
            }}
          >
            <Box
              sx={{
                width: 340,
              }}
              bgcolor={"white"}
              boxShadow={1}
              borderRadius={6}
              p={4}
              position={"relative"}
            >
              {/* <Box
                position={"absolute"}
                top={0}
                bottom={0}
                left={0}
                right={0}
                // zIndex={-1}
                // className="absolute inset-0 z-[-1] bg-cover bg-center"
                style={{
                  backgroundImage: `url(${"https://plus.unsplash.com/premium_photo-1673306778968-5aab577a7365?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"})`,
                  filter: "blur(3px)",
                }}
              /> */}
              <Box display={"flex"} width={"100%"} justifyContent={"center"} pb={1}>
                <img
                  src="images/logo.png"
                  alt="logo"
                  style={{ height: "32px" }}
                  priority
                />
              </Box>
              {/* <Box my={2}>
                <LogoLite />
              </Box> */}
              <Typography
                variant="h5"
                sx={{ fontWeight: "500", marginBottom: "5px" }}
              >
                Log in to your Account
              </Typography>
              {/* <Typography
                variant="h6"
                sx={{
                  fontWeight: "400",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                Welcome back!
              </Typography> */}

              <Typography
                variant="caption"
                color={"red"}
                sx={{
                  fontWeight: "400",
                  fontSize: "14px",
                  marginBottom: "25px",
                }}
              >
                {postError?.action === "error" ? (
                  <span>{postError?.message}</span>
                ) : null}
              </Typography>
              <AuthLogin
                errorMsg={errorMessage}
                password={setpassword}
                username={setUserName}
                onSignIn={handleSignIn}
                handleForgotPassword={handleForgotPassword}
                passwordShown={passwordShown}
                toggleButton={toggleButton}
                postError={postError}
              />
            </Box>
            {isAlertVisible && (
              <AutoHideAlert
                severity={alertSeverity}
                message={alertMessage}
                autoHideDuration={3000}
                open={isAlertVisible}
                onClose={() => setIsAlertVisible(false)}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Login;
