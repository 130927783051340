import * as React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Typography } from "@mui/material";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 800,
  },
});

export default function CusToolTip({ text }) {
  const convertHtmlToText = (text) => {
    const doc = new DOMParser().parseFromString(text, "text/html");
    return doc.body.textContent || "";
  };
  return (
    <div>
      <CustomWidthTooltip title={convertHtmlToText(text)}>
        <Typography fontSize={12} fontWeight={600} py={1.5}>
          {" "}
          {convertHtmlToText(text)}
        </Typography>
      </CustomWidthTooltip>
    </div>
  );
}
