import React from "react";
import { Typography, Button, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

export default function CreateButton({
  heading,
  pagecount,
  onAddClick,
  disableCreate,
}) {
  return (
    <Box sx={{ display: "flex", gap: 1, alignItems: "center", my: 1 }}>
      <Typography
        variant="h4"
        className="nunito_font"
        color={"primary"}
        style={{ fontSize: "16px", fontWeight: 700 }}
      >
        {heading} [{pagecount}]{" "}
      </Typography>
      {(disableCreate === undefined || disableCreate < 1) && (
        <Button
          size="small"
          className="nunito_font_width"
          sx={{
            left: "10px",
            fontSize: "12px",
            marginTop: "1px",
            fontWeight: "300",
            minWidth: "38px",
          }}
          variant="contained"
          onClick={() => onAddClick()}
        >
          <AddIcon style={{ fontSize: "18px" }} />
          <Typography
            variant="body2"
            color="white"
            sx={{ display: { xs: "none", md: "block" } }}
          >
            Add New
          </Typography>
        </Button>
      )}
    </Box>
  );
}
