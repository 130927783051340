import React, {  useEffect } from "react";
import { Alert, Snackbar, Slide } from "@mui/material";

const SlideTransition = (props) => {
  return <Slide {...props} direction="left" />;
};

const AutoHideAlert = ({ severity, message, open, onClose }) => {

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      onClose();
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, [open]);

  const dialogStyle = {
    position: "absolute",
    top: 10,
    right: 10,
    // left: '60%',
    // transform: 'translate(-50%, -50%)',
  };
  return (
    <div style={dialogStyle}>
      <Snackbar  sx={{ zIndex: 3 }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={3000}
        TransitionComponent={SlideTransition}
      >
        <Alert severity={severity} onClose={onClose}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AutoHideAlert;
