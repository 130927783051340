import { Route, Routes } from "react-router-dom";
import Layout from './Components/Layout';
import Attendee from './Pages/Attendee';
import ErrorPage from './Pages/ErrorPage';
import Login from './Pages/(auth)/login/page'
import ForgotPass from './Pages/(auth)/forgot-password/page'
import ChangePass from './Pages/(auth)/change-password/page'
import { ThemeProvider } from "@mui/material/styles";
import { baselightTheme, } from "./utils/theme/DefaultColors";
import User from "./Pages/User";
import Industry from "./Pages/(masters)/Industries";
import Roles from "./Pages/(masters)/Roles";
import Expertise from "./Pages/(masters)/Expertise";
import Banner from "./Pages/(cms)/Banner";
import Event from "./Pages/(cms)/Event";
import Speaker from "./Pages/Speaker";
import Agenda from "./Pages/(cms)/Agenda";
import Moderator from "./Pages/(cms)/Moderator";
import Panelist from "./Pages/(cms)/Panelist";
import Exhibitors from "./Pages/(cms)/Exhibitors";

function App() {
  return (
    <div>
      <ThemeProvider theme={baselightTheme}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot_password" element={<ForgotPass />} />
          <Route path="/change_password" element={<ChangePass />} />
          <Route path="*" element={<ErrorPage />} />
          <Route path="/" element={<Layout />}>
            {/* <Route index element={<Dashboard />} /> */}
            <Route index element={<Attendee />} />
            <Route path="user" element={<User />} />
            <Route path="speaker" element={<Speaker />} />
            <Route path="banner" element={<Banner />} />
            <Route path="event" element={<Event />} />
            <Route path="agenda" element={<Agenda />} />
            <Route path="industry" element={<Industry />} />
            <Route path="moderator" element={<Moderator />} />
            <Route path="roles" element={<Roles />} />
            <Route path="expertise" element={<Expertise />} />
            <Route path="panelist" element={<Panelist />} />
            <Route path="exhibitors" element={<Exhibitors />} />
          </Route>
        </Routes>
      </ThemeProvider>
    </div>
  );
}

export default App;
