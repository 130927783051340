
import React, { useState, useEffect } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import { Link, useLocation } from "react-router-dom";
import {
  AdminPanelSettingsOutlined,
  Announcement,
  ArtTrackOutlined,
  AssignmentInd,
  BrandingWatermark,
  CampaignOutlined,
  ChairAltOutlined,
  Event,
  ExploreOutlined,
  FactoryOutlined,
  Handshake,
  MicExternalOffOutlined,
  MicExternalOnOutlined,
  ModelTraining,
  PersonOutlineRounded,
  SafetyDividerOutlined,
  Settings,
  Speaker,
  ViewAgenda,
  WorkOutlineOutlined,
} from "@mui/icons-material";
import { ContactPhoneOutlined } from "@mui/icons-material";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useMediaQuery, useTheme} from "@mui/material";

const ListItems = ({ open }) => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);
  const [openCategory, setOpenCategory] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const menuCategories = {
    CMS: [
      { id: 1, title: "Banner", link: "/banner", icon: <BrandingWatermark /> },
      { id: 2, title: "Event", link: "/event", icon: <Event /> },
      { id: 3, title: "Agenda", link: "/agenda", icon: <ViewAgenda /> },
      {
        id: 4,
        title: "Moderators",
        link: "/moderator",
        icon: <ModelTraining />,
      },
      {
        id: 5,
        title: "Panelists",
        link: "/panelist",
        icon: <SafetyDividerOutlined />,
      },
      {
        id: 6,
        title: "Exhibitors",
        link: "/exhibitors",
        icon: <Handshake />,
      },
    ],
    ADMIN: [
      { id: 7, title: "Attendee", link: "/", icon: <ChairAltOutlined /> },
      { id: 8, title: "Speaker", link: "/speaker", icon: <MicExternalOnOutlined /> },
      { id: 9, title: "User", link: "/user", icon: <PersonOutlineRounded /> },
    ],
    // master: [
    //   {
    //     id: 9,
    //     title: "Expertise",
    //     link: "/expertise",
    //     icon: <ExploreOutlined />,
    //   },
    //   {
    //     id: 5,
    //     title: "Industry",
    //     link: "/industry",
    //     icon: <FactoryOutlined />,
    //   },
    //   { id: 6, title: "Role", link: "/roles", icon: <WorkOutlineOutlined /> },
    // ],
  };

  useEffect(() => {
    setActiveLink(location.pathname);
    // Determine the category of the current link
    const currentCategory = Object.keys(menuCategories).find((category) =>
      menuCategories[category].some((item) => item.link === location.pathname)
    );
    setSelectedCategory(currentCategory);
  }, [location]);

  const handleCategoryToggle = (category) => {
    setOpenCategory(openCategory === category ? null : category);
  };

  return (
    <div>
      {Object.keys(menuCategories).map((category) => (
        <div key={category}>
          <ListItem
            button
            onClick={() => handleCategoryToggle(category)}
            sx={{
              "&:hover": {
                backgroundColor:
                  selectedCategory === category ? "#0088AB" : "#fff",
              },
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor:
                selectedCategory === category ? "#0088AB" : "#fff",
              color: selectedCategory === category ? "white" : "initial",
            }}
          >
            <ListItemIcon
              sx={{
                color: selectedCategory === category ? "white" : "#0088AB",
                px: 0,
              }}
            >
              {category === "CMS" && <ArtTrackOutlined />}
              {category === "ADMIN" && <AdminPanelSettingsOutlined />}
              {category === "master" && <Settings />}
            </ListItemIcon>
            <ListItemText
              variant="body1"
              // color="#0088AB"
              sx={{
                textTransform: "capitalize",
                color: selectedCategory === category ? "white" : "#0088AB",
              }}
            >
              {category}
            </ListItemText>
            <IconButton size="small">
              {openCategory === category ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon />
              )}
            </IconButton>
          </ListItem>
          <Collapse in={openCategory === category}>
            <List sx={{ py: 0 }}>
              {menuCategories[category].map((item) => (
                <ListItem
                  key={item.id}
                  // disablePadding
                  sx={{
                    px: 1,
                    "&:hover": {
                      backgroundColor:
                        activeLink === item.link ? "#f3f3f3" : "#fff",
                    },
                    backgroundColor:
                      activeLink === item.link ? "#eef0f5" : "#fff",
                    // display: "block",
                    // Adjust the display based on screen size
                    display: isSmallScreen && !open ? "flex" : "block",
                  }}
                >
                  <ListItemButton
                  
                    component={Link}
                    to={item.link}
                    sx={{
                      "&:hover": {
                        backgroundColor:
                          activeLink === item.link ? "#f3f3f3" : "#fff",
                      },
                      py:0,
                      minHeight: 28,
                      justifyContent: open ? "initial" : "center",
                      // px: 2.5,
                      textDecoration: "none",
                      color: "inherit",
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        color: activeLink === item.link ? "#0088AB" : "#64748b",
                        minWidth: 0,
                        py:0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        display: isSmallScreen ? "none" : "flex", // Hide icons on small screens if collapsed
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.title}
                      sx={{
                        opacity: open ? 1 : 0,
                        color: activeLink === item.link ? "#0088AB" : "inherit",
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Collapse>
        </div>
      ))}
    </div>
  );
};

export default ListItems;
