import React from "react";
import { IconButton, Box } from "@mui/material";
import { FilterList, FilterListOff } from "@mui/icons-material";

export default function FilterButton({ HandleChangeFilter, filtersList }) {
  return (
    <Box>
      <IconButton
        size="small"
        onClick={() => HandleChangeFilter()}
        sx={{
          fontSize: "12px",
          fontWeight: "300",
          border: "1px solid #e0e0e0",
          borderRadius: "6px",
          padding: "7px",
        }}
      >
        {filtersList == false ? (
          <FilterList style={{ fontSize: "16px", color: "black" }} />
        ) : (
          <FilterListOff style={{ fontSize: "16px" }} />
        )}
      </IconButton>
    </Box>
  );
}
