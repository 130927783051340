import React, { useState, useEffect, useRef } from "react";
import MasterTable from "../../Components/MasterTable";
import { GridActionsCellItem } from "@mui/x-data-grid";
import {
  Add,
  CloseOutlined,
  MoreVert,
  Close,
  ArrowBack,
  RefreshOutlined,
  Image,
  ImageOutlined,
} from "@mui/icons-material";
import CDrawer from "../../Components/Container/CusDrawer";
import { axiosGet, axiosPost, axiosPostForm } from "../../lib/api";
import GridContainer from "../../Components/Container/GridContainer";
import Cookies from "js-cookie";
import {
  Box,
  Menu,
  MenuItem,
  Grid,
  Stack,
  FormControl,
  InputLabel,
  Select,
  Chip,
  IconButton,
  TextField,
  capitalize,
  Drawer,
  Typography,
  Collapse,
  Badge,
  Tooltip,
  Button,
  Input,
  Autocomplete,
} from "@mui/material";
import CreateButton from "../../Components/buttons/CreateButton";
import CInput from "../../Components/Container/CInput";
import AutoHideAlert from "../../Components/Container/AutoHideAlert";
import AlertDialog from "../../Components/Container/AlertDialog";
import CAlertDialog from "../../Components/Container/CAlertDialog";
import SearchFilter from "../../Components/buttons/SearchFilter";
import FilterButton from "../../Components/buttons/FilterButton";
import { capitalizeText } from "../../lib/config";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CusToolTip from "../../Components/buttons/CusToolTip";
import { sanitizeDescription } from "../../lib/utils";

const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      // width: 160,
    },
  },
};

export default function Agenda() {
  const ACCESS_TOKEN = Cookies.get("spectransyscore_token");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openViewDrawer, setOpenViewDrawer] = useState(false);
  const [openBgDrawer, setOpenBgDrawer] = useState(false);
  const [openActive, setOpenActive] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const fileInputRef = useRef(null);
  const [data, setData] = useState([]);
  const [isAll, setIsAll] = useState(false);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [limit, setLimit] = useState(15);
  const [pageNumber, setPageNumber] = useState(1);
  const [dataCount, setdataCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [openDrawerType, setOpenDrawerType] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [singleData, setSingleData] = useState([]);
  const [postError, setPostError] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filtersList, setfiltersList] = useState(false);
  const [isStatusSelected, setIsStatusSelected] = useState(false);
  const [statusFilter, setStatusFilter] = useState("");
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [rowBtn, setRowBtn] = useState(false);
  const [selectedId, setSelectedId] = useState([]);
  const [speakerData, setSpeakerData] = useState([]);
  const [speaker, setSpeaker] = useState(null);
  const [speakerName, setSpeakerName] = useState("");
  const [speakerId, setSpeakerId] = useState("");
  const [multistatusValue, setMultistatusValue] = useState("");
  const [openMulitiStatus, setOpenMultistatus] = useState(false);
  const [openMulitiDelete, setOpenMultiDelete] = useState(false);
  const [topic, setTopic] = useState("");
  const [detail, setDetail] = useState("");
  const [startTime, setStartTime] = useState("");
  const [image, setImage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const open = Boolean(anchorEl);
  const areCheckboxesEnabled = true;
  const [selectedDate, setSelectedDate] = useState("");
  const [eventDay, setEventDay] = useState("");
  const [error, setError] = useState("");

  const [textLength, setTextLength] = useState("0");
  const handleQuillChange = (value) => {
    const plainText = value.replace(/<[^>]+>/g, "");
    setTextLength(plainText.length)
    if (plainText.length <= 3000) {
      setDetail(value);
      setError(""); 
      setIsButtonDisabled(false);
    } else {
      setError("Exceeded maximum character limit of 3000.");
      setIsButtonDisabled(true);
    }
  };

  // Function to calculate and display the event day
  const handleDateChange = (event) => {
    const newDate = event.target.value;
    setSelectedDate(newDate);

    if (newDate) {
      const startDate = new Date(fromDate);
      const endDate = new Date(newDate);
      const timeDifference = endDate.getTime() - startDate.getTime();
      const daysDifference = Math.round(timeDifference / (1000 * 3600 * 24));

      // Set the event day message
      setEventDay(`Day ${daysDifference + 1}`); // Adding 1 to start from "Day 1"
    }
  };

  
  const maxSizeInMB = 2;
  const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
  const [sizeError, setSizeError] = useState("");
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > maxSizeInBytes) {
        setSizeError(`File size exceeds ${maxSizeInMB} MB. Please choose a smaller file.`);
        setImage(null);
        setImageUrl(null);
        return; // Exit early if the file is too large
      }
  
      setSizeError(null); // Clear any previous errors
      setImage(file);
      setImageUrl(URL.createObjectURL(file));
      // setImageName(file.name);
    }
  }
  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     setImage(file);
  //     setImageUrl(URL.createObjectURL(file));
  //     // setImageName(file.name);
  //   }
  // };

  const resetFileInput = () => {
    setImage(null);
    setImageUrl(null);
    // setImageName("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };
  // Function start to get selected row data
  const getSelectedRowsData = () => {
    return rows.filter((row) => rowSelectionModel.includes(row.id));
  };

  useEffect(() => {
    const selectedRowsData = getSelectedRowsData();
    const selectedDatauniqids = selectedRowsData.map((row) => row.fullData);
    const cusID = selectedDatauniqids.map((row) => row.dataUniqId);
    setSelectedId(cusID);
    if (cusID.length > 0) {
      setRowBtn(true);
    } else {
      setRowBtn(false);
    }
    // let cusId=selectedDatauniqids.filter((data)=>data._id)
  }, [rowSelectionModel]);

  const [time, setTime] = useState("");
  useEffect(() => {
    if (startTime) {
      const dateString = `2024-10-01T${startTime}:00`;
      setTime(dateString);

      // const date = new Date(dateString);

      // // Check if the date is valid
      // if (!isNaN(date.getTime())) {
      //   const formattedTime = date.toISOString();
      // } else {
      //   console.error('Invalid date format:', dateString);
      // }
    }
  }, [startTime]);

  const handleSortModelChange = (model) => {
    if (model.length > 0) {
      const { field, sort } = model[0];
      setSortField(field);
      setSortOrder(sort == "asc" ? "ascending" : "descending");
    } else {
      // Reset to default sorting if needed
      setSortField("createdDate");
      setSortOrder("descending");
    }
  };

  // Function end to get selected row data

  // Function to update searchValue when input changes
  const handleSearchInputChange = (input) => {
    setPageNumber(1);
    setSearchValue(input);
  };

  const handlefilterBadgeVisible = () => {
    if (isStatusSelected || isStatusSelected) {
      // setFilterBadgeVisible(true);
      return true;
    } else {
      // setFilterBadgeVisible(false);
      return false;
    }
  };

  // Toggel filter component
  const HandleChangeFilter = () => {
    setfiltersList(!filtersList);
  };

  const handleRefresh = () => {
    setSearchValue("");
    setIsStatusSelected(false);
    setStatusFilter("");
    fetchData();
  };
  
  const [eventData, setEventData] = useState("");
  const [eventId, setEventId] = useState("");
  const [eventUniq, setEventUniq] = useState("");

  // Toggel Drawer
  const toggleDrawer = (newOpen, type) => () => {
    // type 1 : Create
    // type 2 : Edit
    setOpenDrawer(newOpen);
    setOpenDrawerType(type);
    handleCloseMenu();
    if (type === 1) {
      setStartTime(null);
      setTopic("");
      setImage(null);
      setImageUrl(null);
      setDetail("");
      setSpeaker(null);
      setSpeakerName("");
      setSpeakerId("");
      setSelectedDate("");
      setError('')
    } else {
      setError('')
      setStartTime(singleData?.formattedTime);
      setTopic(singleData.topic);
      setImage(singleData.image);
      setImageUrl(eventData.backgroundImage);
      setDetail(singleData?.detail);
      // setSpeakerName(singleData?.refSpeakerName);
      // setSpeakerId(singleData?.refSpeaker);
      // setSpeaker
      setSpeaker({
        speaker: singleData.refSpeakerName,
        value: singleData.refSpeaker,
      });
    }
  };

  const handleSaveClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    const fullData = item.fullData;
    setSingleData(fullData);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // Open Status change warning box
  const handleStatusOpen = () => {
    setOpenActive(true);
    handleCloseMenu();
  };

  // Open Status change warning box
  const handleViewOpen = () => {
    setOpenViewDrawer(true);
    handleCloseMenu();
  };
  // Open Status change warning box
  const handleBgImgOpen = () => {
    setImageUrl(eventData?.backgroundImage);
    setOpenBgDrawer(true);
    handleCloseMenu();
  };

  const handleDeleteOpen = () => {
    setOpenDel(true);
    handleCloseMenu();
  };

  const handleMultiDelete = () => {
    setOpenMultiDelete(true);
  };

  // open multi status waring
  const handleMultiStatus = (event) => {
    setOpenMultistatus(true);
    setMultistatusValue(event.target.value);
  };

  // Handler to log the page number on page change
  const handlePageChange = (model, newPage) => {
    let page = model.page + 1;
    let pageSize = model.pageSize;
    setPageNumber(page);
    setLimit(pageSize);
  };

  const handlePageSizeChange = (newPageSize) => {
    setLimit(newPageSize);
    setPageNumber(0);
  };

  // Filter Functions Start
  const handleStatusType = (value) => {
    setPageNumber(1);
    setStatusFilter(value);
    setIsStatusSelected(true);
    // handlefilterBadgeVisible(true)
  };
  // Filter Functions End

  const fetchEvent = async () => {
    axiosGet
      .get(`eventsection/get/admin?userToken=${ACCESS_TOKEN}`)
      .then((response) => {
        let data = response.data.data;
        setEventData(data[0]);
        setEventId(data[0]._id);
        setEventUniq(data[0].dataUniqId);
        setImageUrl(data[0].backgroundImage);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const fetchBanner = async () => {
    axiosGet
      .get(`bannersection/get/adminpanel?userToken=${ACCESS_TOKEN}`)
      .then((response) => {
        let data = response.data.data;
        // setEventData(data);
        setFromDate(data[0].editfromDate);
        setToDate(data[0].edittoDate);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const fetchSpeakerData = async () => {
    axiosGet
      .get(
        `speaker/registeration/get/by/adminpanel/?userToken=${ACCESS_TOKEN}&limit=0&activeStatus=1`
        //&activeStatus=${1}
      )
      .then((response) => {
        const data = response.data.data;
        setSpeakerData(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    fetchSpeakerData();
    fetchBanner();
    fetchEvent();
  }, []);

  const speakerOption = speakerData?.map((item) => ({
    speaker: `${item.firstName} ${item.lastName}`,
    value: item.dataUniqId,
  }));

  const fetchData = async () => {
    setIsLoading(true);
    axiosGet
      .get(
        `agendasection/get/admin?userToken=${ACCESS_TOKEN}&page=${pageNumber}&limit=${limit}&sortField=${sortField}&sortOrder=${sortOrder}&isAll=${isAll}&topic=${searchValue}&activeStatus=${
          statusFilter == 3 ? "" : statusFilter
        }`
        // &sortField=${sortField}&sortOrder=${sortOrder}
        // &detail=${searchValue}
      )
      // &activeStatus=${statusFilter == 3 ?"":statusFilter}
      .then((response) => {
        if (response.data.message === "Agendas retrieved successfully") {
          setData(response.data.data);
          setdataCount(response.data.totalItems);
          setPageNumber(pageNumber === 0 ? 1 : pageNumber);
          setPageCount(response.data.total_pages);
          setIsLoading(false);
          // setIsAlertVisible(true);
          // setAlertSeverity("success");
          // setAlertMessage("response.data.error");
        } else {
          setIsLoading(false);
          setPostError(response.data.error);
          setIsAlertVisible(true);
          setAlertSeverity("error");
          setAlertMessage("Something went wrong.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(true);
      });
  };

  useEffect(() => {
    fetchData();
  }, [pageNumber, limit, searchValue, statusFilter, sortField, sortOrder]);

  // Funtion for create new data or edit existing data
  const handleSubmit = () => {
    setIsButtonDisabled(true);
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
    setIsLoading(true);

    const jsonData = {
      userToken: ACCESS_TOKEN,
      dataUniqId: singleData?.dataUniqId,
      startTime: time,
      // startTime: startTime,
      topic: topic,
      detail: sanitizeDescription(detail),
      refEventId: eventUniq,
      refSpeaker: speaker?.value,
      refSpeakerName: speaker?.speaker,
      eventDay: eventDay,
      eventDate: selectedDate,
    };
    try {
      if (openDrawerType == 1) {
        axiosPost
          .post(`agendasection/create`, jsonData)
          .then((response) => {
            // Handle the successful POST response here
            // if (response.data.message === "Agenda created successfully") {
            // If response data action is 200, show the alert
            setIsAlertVisible(true);
            setAlertSeverity("success");
            setAlertMessage(response.data.message);
            // Cookies.remove("uuid");
            setOpenDrawer(false);
            setIsLoading(false);
            fetchData();
            // } else {
            //   setIsAlertVisible(true);
            //   setAlertSeverity("error");
            //   setIsLoading(false);
            //   setAlertMessage(response.data.message);
            //   setPostError(response.data.message);
            // }
          })
          .catch((error) => {
            // Handle POST errors here
            console.error("POST Error:", error);
            setIsAlertVisible(true);
            setAlertSeverity("error");
            setIsLoading(false);
            setAlertMessage(error?.response.data.error);
          });
      } else {
        axiosPost
          .put(`agendasection/update`, jsonData)
          .then((response) => {
            // Handle the successful POST response here
            if (response.data.message === "Agenda updated successfully") {
              // If response data action is 200, show the alert
              setIsAlertVisible(true);
              setAlertSeverity("success");
              setAlertMessage(response.data.message);
              // Cookies.remove("uuid");
              setOpenDrawer(false);
              setIsLoading(false);
              fetchData();
              setSingleData([]);
            } else {
              setIsAlertVisible(true);
              setAlertSeverity("error");
              setIsLoading(false);
              setAlertMessage(response.error);
              // setPostError(response.data.message);
            }
          })
          .catch((error) => {
            // Handle POST errors here
            console.error("POST Error:", error);
            setIsAlertVisible(true);
            setAlertSeverity("error");
            setIsLoading(false);
            setAlertMessage(error?.response.data.error);
          });
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  // Funtion for create new data or edit existing data
  const handleBgSubmit = () => {
    setIsButtonDisabled(true);
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
    setIsLoading(true);
    const formData = new FormData();
    formData.append("userToken", ACCESS_TOKEN);
    formData.append("background", image);
    formData.append("eventId", eventUniq);

    try {
      if (openDrawerType == 1) {
        axiosPostForm
          .post(`speaker/bgimage`, formData)
          .then((response) => {
            // Handle the successful POST response here
            if (
              response.data.message ===
              "Background image uploaded and event updated successfully"
            ) {
              // If response data action is 200, show the alert
              setIsAlertVisible(true);
              setAlertSeverity("success");
              setAlertMessage("Background image updated");
              // Cookies.remove("uuid");
              setOpenBgDrawer(false);
              setIsLoading(false);
              fetchData();
            } else {
              setIsAlertVisible(true);
              setAlertSeverity("error");
              setIsLoading(false);
              setAlertMessage(response.data.message);
              //   setPostError(response.data.message);
            }
          })
          .catch((error) => {
            // Handle POST errors here
            console.error("POST Error:", error);
            setIsAlertVisible(true);
            setAlertSeverity("error");
            setIsLoading(false);
            setAlertMessage(error?.response.data.error);
          });
      } else {
        axiosPostForm
          .put(`eventsection/update/agenda/bgimage`, FormData)
          .then((response) => {
            // Handle the successful POST response here
            if (
              response.data.message === "Background image updated successfully"
            ) {
              // If response data action is 200, show the alert
              setIsAlertVisible(true);
              setAlertSeverity("success");
              setAlertMessage(response.data.message);
              // Cookies.remove("uuid");
              setOpenDrawer(false);
              setIsLoading(false);
              fetchData();
              setSingleData([]);
            } else {
              setIsAlertVisible(true);
              setAlertSeverity("error");
              setIsLoading(false);
              setAlertMessage(response.error);
              // setPostError(response.data.message);
            }
          })
          .catch((error) => {
            // Handle POST errors here
            console.error("POST Error:", error);
            setIsAlertVisible(true);
            setAlertSeverity("error");
            setIsLoading(false);
            setAlertMessage(error?.response.data.error);
          });
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  // Funtion for change status of single data
  const handleActiveChange = () => {
    setIsLoading(true);
    const jsonData = {
      userToken: ACCESS_TOKEN,
      // dataUniqIds: [singleData.dataUniqId],
      dataUniqIds:
        selectedId.length > 0 ? selectedId : [singleData?.dataUniqId],
      activeStatus: multistatusValue || singleData.activeStatus === 1 ? 0 : 1,
    };
    axiosPost
      .post(`agendasection/change/activestatus`, jsonData)
      .then((response) => {
        if (response.data.message === "Active status updated successfully") {
          // setEffectToggle(!effectToggle);
          // setSelectedItems([]);
          // setActionData("");
          setAlertMessage("Updated successfully.");
          setIsAlertVisible(true);
          setAlertSeverity("success");
          handleCloseMenu();
          setIsLoading(false);
          fetchData();
          setSelectedId("");
          setMultistatusValue("");
          setRowSelectionModel("");
        }
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error:", error);
      });
  };

  // Funtion for delete single data
  const handleDelete = () => {
    setIsLoading(true);
    const jsonData = {
      userToken: ACCESS_TOKEN,
      dataUniqIds:
        selectedId.length > 0 ? selectedId : [singleData?.dataUniqId],
    };
    axiosPost
      .delete(`agendasection/delete`, { data: jsonData })
      .then((response) => {
        // if (response.data.message === "Attendees deleted successfully") {
        // setEffectToggle(!effectToggle);
        handleCloseMenu();
        setAlertMessage("Deleted successfully.");
        setIsAlertVisible(true);
        setAlertSeverity("success");
        setIsLoading(false);
        fetchData();
        setSelectedId("");
        setMultistatusValue("");
        setRowSelectionModel("");
        // }
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error:", error);
      });
  };

  const columns = [
    // {
    //   field: "id",
    //   headerName: "#",
    //   flex: 1,
    //   width: 60,
    //   maxWidth: 80,
    //   // disableColumnMenu: true,
    //   headerClassName: "super-app-theme--header",
    // },
    {
      field: "startTime",
      headerName: "Start Time",
      flex: 1,
      minWidth: 100,
      maxWidth: 200,
      // disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
      sortable: false,
    },
    {
      field: "eventDay",
      headerName: "Day",
      flex: 1,
      minWidth: 100,
      maxWidth: 200,
      // disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "topic",
      headerName: "Topic",
      flex: 1,
      minWidth: 100,
      maxWidth: 200,
      // disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
      // sortable: false,
    },
    {
      field: "speaker",
      headerName: "Speaker",
      flex: 1,
      minWidth: 100,
      maxWidth: 200,
      // disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
      // sortable: false,
    },
    {
      field: "detail",
      headerName: "Detail",
      flex: 1,
      minWidth: 100,
      // disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => <CusToolTip text={params.value} arrow />,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      maxWidth: 200,
      minWidth: 100,
      // disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        const status = params.value; // 'app' or 'rej'
        const style = {
          color:
            status === "Inactive"
              ? "#FFA500"
              : status === "Active"
              ? "#008000"
              : null,
        };

        return <span style={style}>{status}</span>;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      // disableColumnMenu: true,
      flex: 1,
      maxWidth: 100,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <GridActionsCellItem
          icon={<MoreVert />}
          label="Save"
          sx={{ position: "relative", color: "primary.main" }}
          onClick={(event) => handleSaveClick(event, params.row)}
        />
      ),
    },
  ];

  const rows = data?.map((item, index) => ({
    id: index + 1,
    startTime: item.startTime,
    eventDay: item.eventDay,
    topic: item.topic,
    speaker: capitalize(item.refSpeakerName),
    detail: item.detail,
    status:
      item.activeStatus === 0
        ? "Inactive"
        : item.activeStatus === 1
        ? "Active"
        : null,
    fullData: item,
  }));

  const title = "Agenda";

  const boxSx = {
    "& .super-app-theme--header": {
      backgroundColor: "#0088AB",
      color: "white",
      fontSize: "16px",
      fontWeight: "bold",
    },
    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
      {
        backgroundColor: "#0088AB",
      },
    "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
      color: "white",
    },
    "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root.Mui-checked": {
      color: "white",
    },
  };

  const FilterComponent = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 1,
        }}
      >
        <IconButton onClick={handleRefresh} size="small">
          <RefreshOutlined />
        </IconButton>
        <FormControl size="small" sx={{ minWidth: "150px" }}>
          <InputLabel sx={{ fontSize: "14px" }} id="game">
            {"Status"}
          </InputLabel>
          <Select
            MenuProps={MenuProps}
            sx={{
              fontSize: "14px",
              height: "34px",
              bgcolor: isStatusSelected ? "#185aa617" : undefined,
            }}
            placeholder={"Status"}
            labelId="status"
            id="status"
            label={"Status"}
            value={statusFilter}
            onChange={(e) => handleStatusType(e.target.value)}
          >
            <MenuItem sx={{ fontSize: "14px" }} value={3}>
              All
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={1}>
              Active
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={0}>
              Inactive
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
    );
  };

  const handleSpeaker = (event, newValue) => {
    setSpeaker(newValue);
  };

  return (
    <>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        flexDirection={{ xs: "column", md: "row" }}
      >
        <Box display={"flex"} alignItems={"center"} gap={1}>
          <CreateButton
            heading={title}
            pagecount={dataCount}
            onAddClick={toggleDrawer(true, 1)}
          />
          <Button
            size="small"
            className="nunito_font_width"
            sx={{
              height: 26,
              left: "10px",
              fontSize: "12px",
              marginTop: "1px",
              fontWeight: "300",
              minWidth: "38px",
              display: "flex",
              gap: 0.5,
              alignItems: "center",
            }}
            variant="contained"
            onClick={handleBgImgOpen}
          >
            <ImageOutlined style={{ fontSize: "18px" }} />
            <Typography
              variant="body2"
              color="white"
              sx={{ display: { xs: "none", md: "block" } }}
            >
              Add Background
            </Typography>
          </Button>
        </Box>
        <Box display={"flex"} alignItems={"center"} gap={1.5}>
          {" "}
          <SearchFilter
            onSearchButtonClick={handleSearchInputChange}
            searchValue={searchValue}
          />
          <Badge
            color="secondary"
            variant="dot"
            invisible={!handlefilterBadgeVisible()}
          >
            <FilterButton
              HandleChangeFilter={HandleChangeFilter}
              filtersList={filtersList}
            />
          </Badge>
        </Box>
      </Box>

      <Collapse in={filtersList} timeout="auto" unmountOnExit>
        <Box sx={{ display: "flex", justifyContent: "end", py: 0.5 }}>
          {FilterComponent()}
        </Box>
      </Collapse>
      <Box sx={{ overflow: "auto" }}>
        <MasterTable
          rows={rows}
          columns={columns}
          areCheckboxesEnabled={areCheckboxesEnabled}
          title={title}
          limit={limit}
          loading={isLoading}
          boxSx={boxSx}
          handlePageChange={handlePageChange}
          handlePageSizeChange={handlePageSizeChange}
          rowCount={dataCount}
          paginationModel={{ pageNumber, limit }}
          handleMultiDelete={handleMultiDelete}
          handleMultiStatus={handleMultiStatus}
          multistatusValue={multistatusValue}
          setRowSelectionModel={setRowSelectionModel}
          rowBtn={rowBtn}
          rowSelectionModel={rowSelectionModel}
          handleSortModelChange={handleSortModelChange}
        />
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={toggleDrawer(true, 2)} sx={{ width: 140 }}>
          Edit
        </MenuItem>
        <MenuItem onClick={handleViewOpen}>View</MenuItem>
        <MenuItem onClick={handleStatusOpen}>
          Mark as {singleData.activeStatus === 0 ? "active" : "inactive"}
        </MenuItem>
        <MenuItem onClick={handleDeleteOpen}>Delete</MenuItem>
      </Menu>

      {/* main drawer  */}
      <CDrawer
        open={openDrawer}
        close={() => setOpenDrawer(false)}
        title={title}
        loading={isButtonDisabled}
        buttonName={openDrawerType === 1 ? "Create" : "Save"}
        openType={openDrawerType}
        onSave={handleSubmit}
        width={500}
      >
        {/* Content for CDrawer */}
        <GridContainer spacing={2}>
          <Grid item xs={12}>
            <CInput
              label="Topic"
              value={topic}
              name="Key"
              onChange={(e) => setTopic(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              size="small"
              label="Event Date"
              type="date"
              value={selectedDate}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: { min: fromDate, max: toDate },
              }}
              onChange={handleDateChange}
              // onChange={(e) => setCertExpire(e.target.value)}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField size="small" label="Day" value={eventDay} fullWidth />
          </Grid>
          <Grid item xs={12} md={4}>
            <CInput
              label="Start Time"
              type="time"
              variant="outlined"
              value={startTime}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setStartTime(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            {/* <FormControl size="small" fullWidth sx={{ minWidth: "180px" }}>
              <InputLabel sx={{ fontSize: "14px" }} id="game">
                {"Speaker"}
              </InputLabel>
              <Select
                MenuProps={MenuProps}
                sx={{
                  fontSize: "14px",
                  height: "34px",
                  // bgcolor: isAOESelected ? "#185aa617" : undefined,
                }}
                placeholder={"Speaker"}
                label={"Speaker"}
                value={speaker}
                onChange={(e) => setSpeaker(e.target.value)}
              >
                <MenuItem sx={{ fontSize: "14px" }} value={1}>
                  All
                </MenuItem>
                {speakerData?.map((item, index) => (
                  <MenuItem
                    key={index}
                    sx={{ fontSize: "14px", textTransform: "capitalize" }}
                    value={item}
                  >
                    {item.firstName} {item.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
            <Autocomplete
              sx={{ textTransform: "capitalize" }}
              size="small"
              fullWidth
              options={speakerOption}
              getOptionLabel={(option) => capitalize(option?.speaker)}
              autoComplete
              includeInputInList
              value={speaker}
              onChange={handleSpeaker}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Speaker"
                  sx={{ textTransform: "capitalize" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            Description
            <ReactQuill
              theme="snow"
              value={detail}
              onChange={handleQuillChange}
              style={{ width: 450 }}
            />
             <Box display={"flex"} justifyContent={"space-between"}>
              {error ? (
                <Typography variant="caption" color="red">
                  {error}
                </Typography>
              ) : (
                <Typography variant="caption" color="#4b5563">
                  Maximum 3000 characters.
                </Typography>
              )}
              <Typography variant="caption" color="#4b5563">
                {textLength}/3000
              </Typography>
            </Box>
            {/* {error ? (
              <Typography variant="caption" color="red">
                {error}
              </Typography>
            ) : (
              <Typography variant="caption" color="#4b5563">
                Maximum 3000 characters.
              </Typography>
            )}{" "} */}
          </Grid>
        </GridContainer>
      </CDrawer>
      {/* main drawer end  */}

      {/* background drawer  */}
      <CDrawer
        open={openBgDrawer}
        close={() => setOpenBgDrawer(false)}
        title={title}
        loading={isButtonDisabled}
        buttonName="Save"
        // openType={openDrawerType}
        onSave={handleBgSubmit}
      >
        {/* Content for CDrawer */}
        <GridContainer spacing={2}>
          <Box width={"100%"}>
            <Grid item xs={12} padding={1}>
              <Typography variant="body1" color="initial">Backgroud Image</Typography>
              
              <Stack width={"100%"}>
                {imageUrl ? (
                  <div
                    style={{
                      // aspectRatio: "4/3",
                      height: "180px",
                      width: "100%",
                      border: "2px dashed #ccc",
                      borderRadius: "10px",
                      padding: "4px",
                      textAlign: "center",
                      position: "relative",
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "10px",
                      }}
                      src={imageUrl}
                      alt={"image"}
                    />
                    <IconButton
                      sx={{
                        padding: 0.5,
                        px: 1,
                        position: "absolute",
                        top: "4px",
                        right: "4px",
                        background: "#f44336",
                      }}
                      onClick={resetFileInput}
                    >
                      <CloseOutlined
                        sx={{ color: "#fff", width: 16, aspectRatio: 1 / 1 }}
                      />
                    </IconButton>
                  </div>
                ) : (
                  <label htmlFor="image-upload" style={{ width: "100%" }}>
                    <div
                      style={{
                        // aspectRatio: "4/3",
                        width: "100%",
                        height: "180px",
                        // height: "130px",
                        // height: "146px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "2px dashed #ccc",
                        borderRadius: "10px",
                        padding: "8px",
                        textAlign: "center",
                      }}
                    >
                      <Box
                        component="img"
                        sx={{
                          // height: "100%",
                          width: 110,
                        }}
                        alt="Placeholder"
                        src="/images/upload1280.svg"
                      />
                    </div>
                    <input
                      id="image-upload"
                      type="file"
                      ref={fileInputRef}
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                      {sizeError ? (
                      <Typography variant="caption" color="red">
                        {sizeError}
                      </Typography>
                    ) : (
                      <Typography variant="caption" color="#4b5563">
                        Maximum image size is 2MB.
                      </Typography>
                    )}
                    {/* <Typography variant="caption" color="#0088AB" px={1}>
                      Upload an image that's under 2MB.
                    </Typography> */}
                  </label>
                )}
              </Stack>
            </Grid>
          </Box>
        </GridContainer>
      </CDrawer>
      {/* background drawer end  */}

      {/* view drawer  */}
      <Drawer
        anchor={"right"}
        open={openViewDrawer}
        onClose={() => setOpenViewDrawer(false)}
        title={title}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            py: 1,
            borderBottom: "1px solid #3636363b",
          }}
        >
          <IconButton onClick={() => setOpenViewDrawer(false)} size="small">
            <ArrowBack style={{ color: "black" }} />
          </IconButton>
          <Typography
            variant="h6"
            className="nunito_font"
            style={{
              fontSize: "18px",
              fontWeight: 700,
              color: "#185AA6",
            }}
          >
            View {title}
          </Typography>
        </Box>
        {singleData ? (
          <Box sx={{ width: 400, p: 2 }}>
            <GridContainer>
              <Grid item xs={12} display={"flex"} flexDirection={"column"}>
                <Typography sx={{ color: "gray" }}>Time:</Typography>
                <Typography
                  variant="h5"
                  component="h2"
                  sx={{ px: 1.5, fontSize: "12px" }}
                  textTransform={"capitalize"}
                >
                  {singleData?.startTime}
                </Typography>
              </Grid>
              <Grid item xs={12} display={"flex"} flexDirection={"column"}>
                <Typography sx={{ color: "gray" }}>Topic</Typography>
                <Typography
                  variant="h5"
                  component="h2"
                  sx={{ px: 1.5, fontSize: "12px" }}
                  textTransform={"capitalize"}
                >
                  {singleData?.topic}
                </Typography>
              </Grid>
              <Grid item xs={12} display={"flex"} flexDirection={"column"}>
                <Typography sx={{ color: "gray" }}>Detail</Typography>
                <Typography
                  variant="h5"
                  component="h2"
                  sx={{ px: 1.5, fontSize: "12px" }}
                  textTransform={"capitalize"}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: singleData?.detail }}
                  />
                </Typography>
              </Grid>
            </GridContainer>
          </Box>
        ) : (
          "Loading..."
        )}
      </Drawer>
      {/* view drawer end  */}

      <AlertDialog
        onsubmit={handleActiveChange}
        open={openActive}
        handleClose={() => setOpenActive(false)}
        text={`Are you sure want to ${
          singleData.activeStatus == 1 ? "Active" : "Inactive"
        } ?`}
      ></AlertDialog>
      <AlertDialog
        onsubmit={handleActiveChange}
        open={openMulitiStatus}
        handleClose={() => setOpenMultistatus(false)}
        text={`Are you sure want to change status of ${selectedId.length} items?`}
      ></AlertDialog>
      <AlertDialog
        onsubmit={handleDelete}
        open={openDel}
        handleClose={() => setOpenDel(false)}
        text={"Are you sure want to delete ?"}
      ></AlertDialog>
      <AlertDialog
        onsubmit={handleDelete}
        open={openMulitiDelete}
        handleClose={() => setOpenMultiDelete(false)}
        text={`Are you sure want to delete ${selectedId.length} items?`}
      ></AlertDialog>
      {isAlertVisible && (
        <AutoHideAlert
          severity={alertSeverity}
          message={alertMessage}
          autoHideDuration={3000}
          open={isAlertVisible}
          onClose={() => setIsAlertVisible(false)}
        />
      )}
    </>
  );
}
