export const formatAsINR = (number) => {
    return new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR'
    }).format(number);
  }

  export const sanitizeDescription = (description) => {
    // Regular expression to match consecutive <p><br></p> and replace them with just one <p><br></p>
    return description?.replace(/(<p><br><\/p>)+/g, "<p><br></p>");
  };


  export const validateLinkedInURL = (url) => {
    const linkedInPattern = /^(https?:\/\/)?(www\.)?linkedin\.com\/[a-zA-Z]{2,3}\/[a-zA-Z0-9-]+(\/[a-zA-Z0-9-]*)?(\/?)(\?[^ ]+)?(\/?)$/;
    return linkedInPattern.test(url);
  };